export const feedbackTypes = [
  "No Answer",
  "Interested",
  "Not Interested",
  "Switched Off",
  "Call Back",
  "Duplicate",
  "Follow Up",
  "Wrong Number",
  "Low Budget",
];
