import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";

function ClientProjectInformationCard({ lead }) {
  const theme = useTheme();

  const numQuestions = lead?.otherQuestions.length;
  let spanNum = numQuestions <= 2 ? numQuestions + 2 : numQuestions + 1;

  return (
    <Box
      gridColumn="span 12"
      gridRow={`span ${spanNum}`}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      borderRadius="0.55rem"
    >
      <Typography variant="h5" color={theme.palette.secondary[300]}>
        Other Questions on the Form
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        mt="5px"
        height="80%"
      >
        {lead?.otherQuestions.map((item, index) => (
          <FlexBetween key={index}>
            <Typography sx={{ mr: "5px" }}>{item.question || ""}</Typography>
            <Typography fontWeight="bold">{item.answer || ""}</Typography>
          </FlexBetween>
        ))}
      </Box>
    </Box>
  );
}

export default ClientProjectInformationCard;
