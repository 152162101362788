import React, { useState, useEffect } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  TextField,
  MenuItem,
  Button,
  LinearProgress,
  styled,
  Typography,
} from "@mui/material";
import {
  useGetAllClientTeamMembersQuery,
  useGetAllClientsQuery,
  useGetAllProjectsQuery,
  useGetClientLeadsQuery,
  useGetSingleClientQuery,
} from "state/api";
import Header from "components/Header";
import ClientStatBox from "components/ClientStatBox";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ClientBulkAssignModal from "./clientModals/ClientBulkAssignModal";
import useStoreExistingFilters from "hooks/useStoreExistingFilters";
import { setMode } from "state";
import useUnassignLeads from "hooks/useUnassignLead";
import { useNavigate } from "react-router-dom";
import { feedbackTypes } from "constants/SelectOptions";

function ClientLeads() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const { storeExistingFilters, getAndRemoveFilters } =
    useStoreExistingFilters();

  const { data: allClients } = useGetAllClientsQuery();
  const [client, setClient] = useState();

  const { data: teamMembers } = useGetAllClientTeamMembersQuery(client?._id);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [isPointer, setIsPointer] = useState(false);

  const { unassignLeads, isLoading, error } = useUnassignLeads();

  useEffect(() => {
    // Assuming unassignLeads sets isLoading to false when it's done
    if (!isLoading) {
      window.location.reload();
    }
  }, [isLoading]);

  const mode = useSelector((state) => state.global.mode);

  useEffect(() => {
    const client = allClients?.filter((c) => c.email == user.email);
    client ? setClient(client[0]) : setClient();
  }, [user, allClients]);

  const theme = useTheme();
  const { data: allProjects } = useGetAllProjectsQuery();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });
  const [search, setSearch] = useState("");

  dayjs.extend(utc);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const [campaignPrefix, setCampaignPrefix] = useState("");

  const [feedback, setFeedback] = useState("");

  const filters = {
    startDate,
    endDate,
    feedback,
    // clientName,
    mode, // Include the mode filter
  };

  const handlePopupClose = () => {
    storeExistingFilters(filters);
    setIsPopupOpen(false);
  };

  const handlePopupOpen = () => {
    if (selectedRows.length > 0) {
      setIsPopupOpen(true);
    }
  };

  const handleUnassignLeads = async () => {
    storeExistingFilters(filters);

    if (selectedRows.length > 0) {
      try {
        await unassignLeads(selectedRows, "teamMember");
      } catch (error) {
        console.error("Error while unassigning leads:", error);
        // Handle error if needed
      }
    }
  };

  const { data: leadData } = useGetClientLeadsQuery({
    id: client ? client._id : "",
    page,
    pageSize,
    sort: JSON.stringify(sort ? sort : { field: "createdAt", sort: "desc" }),
    search,
    startDate,
    endDate,
    campaignPrefix: campaignPrefix.toLowerCase(),
    feedback,
  });

  const [leads, setLeads] = useState(null);
  const [total, setTotal] = useState(null);

  const [clientProjects, setClientProjects] = useState(null);
  const [selectedProject, setSelectedProject] = useState({
    _id: "",
    name: "",
    campaignPrefix: "",
  });

  useEffect(() => {
    if (leadData && teamMembers) {
      let tempArray = [];
      for (const lead of leadData.leads) {
        const newDisplay = {
          ...lead,
          teamMemberName:
            teamMembers.teamMembers.find((t) => t._id === lead.teamMemberId)
              ?.name || "",
        };
        tempArray.push(newDisplay);
      }

      setLeads(tempArray);
      setTotal(leadData?.total);
    }
  }, [leadData, teamMembers]);

  useEffect(() => {
    let clientProjs = [];
    if (client && allProjects) {
      for (const clientProject of client?.projects) {
        for (const project of allProjects) {
          if (clientProject.project === project._id) {
            clientProjs.push({
              _id: clientProject.project,
              name: project.name,
              campaignPrefix: project.campaignPrefix,
            });
          }
        }
      }
    }

    setClientProjects(clientProjs);
  }, [allProjects, client]);

  useEffect(() => {
    setCampaignPrefix(selectedProject.campaignPrefix);
  }, [selectedProject]);

  const handleFeedbackUpdate = async (updatedRow, originalRow) => {
    try {
      const diff = getDifferentValuesFromObject(updatedRow, originalRow);
      diff.forEach(async (key) => {
        if (key == "status") {
          const response = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editStatus/${updatedRow._id}`,
            {
              status: updatedRow.status,
            },
            configToken
          );
          updateLocalLead(updatedRow);
        } else if (key == "feedback" || key == "writtenFeedback") {
          const response = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editFeedback/${updatedRow._id}`,
            {
              feedback: updatedRow.feedback,
              writtenFeedback: updatedRow.writtenFeedback,
            },
            configToken
          );
          updateLocalLead(updatedRow);
        }
        // apiRef.current.stopCellEditMode({ id: updatedRow._id, field: key });
      });
      // const response = await axios.patch(
      //   `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editFeedback/${updatedRow._id}`,
      //   { feedback: updatedRow.feedback },
      //   configToken
      // );
      return updatedRow;
    } catch (error) {
      console.log(error);
    }
  };

  const getDifferentValuesFromObject = (newRow, oldRow) => {
    const keys1 = Object.keys(newRow);
    const keys2 = Object.keys(oldRow);

    const differentKeys = [];

    // Iterate through keys of obj1
    keys1.forEach((key) => {
      // Check if the key exists in obj2 and the values are different
      if (keys2.includes(key)) {
        if (newRow[key] !== oldRow[key]) {
          differentKeys.push(key);
        }
      } else {
        differentKeys.push(key);
      }
    });

    return differentKeys;
  };

  const BorderLinearProgress = styled(LinearProgress)(({ statuscolor }) => ({
    height: "14px",
    width: "88px",
    borderRadius: "7px",
    backgroundColor: " #ebf5fb",
    "& .MuiLinearProgress-bar": {
      backgroundColor: `${statuscolor}`,
      transition: "none",
      transformOrigin: "left",
    },
  }));

  const columns = [
    {
      field: "createdAt",
      headerName: "Date",
      // flex: 0.3,
      width: 90,
      valueFormatter: (params) => {
        const date = new Date(params.value);

        // Get the local time components
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are 0-based
        const year = date.getFullYear();

        // Create the formatted date string
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
      },
    },
    {
      field: "campaignName",
      headerName: "Campaign Name",
      // flex: 0.4,
      width: 150,
    },
    {
      field: "name",
      headerName: "Full Name",
      // flex: 0.5,
      width: 150,
      // renderCell: (props) => (
      //   <div
      //     style={{
      //       cursor: isPointer ? 'pointer' : 'default',
      //     }}
      //   >
      //     {props.value}
      //   </div>
      // )
    },
    {
      field: "email",
      headerName: "Email",
      // flex: 0.4,
      width: 150,
    },
    {
      field: "phoneNumberOne",
      headerName: "Phone Number 1",
      // flex: 0.5,
      width: 150,
    },
    {
      field: "phoneNumberTwo",
      headerName: "Phone Number 2",
      // flex: 0.5,
      width: 150,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      // flex: 0.3,
      width: 150,
    },
    {
      field: "otherQuestions",
      headerName: "Other Questions",
      width: 200,
      renderCell: (params) => (
        <Box>
          {params.value.map((questionObj, index) => (
            <div key={index}>
              {`${questionObj.question} : ${questionObj.answer}`}
            </div>
          ))}
        </Box>
      ),
    },
    {
      field: "teamMemberName",
      headerName: "Team Member",
      // flex: 0.4,
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        const options = {
          "Attempted First Call": [25, "#7b00d9"],
          "Followed Up": [50, "#d95e00"],
          "Meeting Set": [75, "#b5d900"],
          "Closed Deal": [100, "#05e832"],
        };
        const val = params?.value ? options[params.value][0] : 0;
        const color = params?.value ? options[params.value][1] : "#05e832";
        return (
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            justifyContent="center"
            rowGap="2px"
            // alignContent="space-between"
            alignItems="center"
          >
            <BorderLinearProgress
              color="success"
              statuscolor={color}
              sx={{ color: theme.palette.secondary[200] }}
              variant="determinate"
              value={val}
            />
            <Typography variant="body2" color={theme.palette.primary[500]}>{`${
              params?.value ? params.value : "Need to Call"
            }`}</Typography>
          </Box>
        );
      },
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "Attempted First Call",
        "Followed Up",
        "Meeting Set",
        "Closed Deal",
        "",
      ],
    },
    {
      field: "feedback",
      headerName: "Feedback",
      // flex: 0.4,
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [...feedbackTypes, ""],
    },
    {
      field: "writtenFeedback",
      headerName: "Notes",
      // flex: 0.4,
      width: 300,
      editable: true,
    },
  ];

  useEffect(() => {
    // Get and remove filters from localStorage
    const filters = getAndRemoveFilters();

    if (filters) {
      // Apply filters to state
      setStartDate(filters.startDate || "");
      setEndDate(filters.endDate || "");
      setFeedback(filters.feedback || "");
      // setClientProjects(filters.clientName || "");

      // Apply mode filter from localStorage
      dispatch(setMode(filters.mode));
    }
  }, []);

  const handleCellOver = (event) => {
    const field = event.currentTarget.dataset.field;
    if (field === "name") {
      setIsPointer(true);
    }
  };

  const handleCellOut = (event) => {
    const field = event.currentTarget.dataset.field;
    if (field === "name") {
      setIsPointer(false);
    }
  };

  const updateLocalLead = (updatedRow, status = false) => {
    if (status) {
      // find the index of the updatedRow in the leads array
      const index = leads.findIndex((obj) => obj._id === updatedRow);
      if (index !== -1) {
        // If an object with the same _id is found, replace it with updatedRow
        let oldLeads = [...leads];
        const oldLead = oldLeads[index];
        const newLead = { ...oldLead, status: "Attempted First Call" };
        oldLeads.splice(index, 1, newLead);
        setLeads(oldLeads);
      }
    } else {
      // find the index of the updatedRow in the leads array
      const index = leads.findIndex((obj) => obj._id === updatedRow._id);
      if (index !== -1) {
        // If an object with the same _id is found, replace it with updatedRow
        let oldLeads = [...leads];
        oldLeads.splice(index, 1, updatedRow);
        setLeads(oldLeads);
      }
    }
  };

  const handleRowDoubleClick = async (params) => {
    if (params.field === "name") {
      const rowId = params.row._id;
      navigate(`/leads/${rowId}`);
    } else if (
      params.field === "phoneNumberOne" ||
      params.field === "phoneNumberTwo"
    ) {
      window.open(`tel:${params.row[params.field]}`, "_self");
      const index = leads.findIndex((obj) => obj._id === params.row._id);
      const lead = leads[index];
      if (!lead.status) {
        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editStatus/${params.row._id}`,
          {
            status: "Attempted First Call",
          },
          configToken
        );
        updateLocalLead(params.row._id, true);
      } else {
        const res = await axios.put(
          `${process.env.REACT_APP_BASE_URL}leadsServer/leadHistory/createDoc/${params.row._id}`,
          {
            type: "call",
            info: `${client?.name} called lead.`,
          },
          configToken
        );
      }
    }
  };

  const dateMarginTop = isNonMediumScreens ? "0px" : "20px";
  const flexDirection = isNonMediumScreens ? "row" : "column";
  const filterMarginTop = isNonMediumScreens ? "40px" : "0px";

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title={`${client?.name}`}
          subtitle={`Specific information and data for ${client?.name}`}
        />
      </FlexBetween>
      {/* <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(6, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12"}
        }}
      >
        {client ? (
          client.projects.map(project => (
            <ClientStatBox key={project._id} _id={project.project} numberLeadsRequested={project.numberLeads} numberLeadsReceived={project.numberLeadsReceived}/>
          ))
        ) : (
          <>Loading...</>
        )}
      </Box> */}
      <Box
        mt={filterMarginTop}
        display="flex"
        flexDirection={flexDirection}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          {clientProjects ? (
            <TextField
              select
              label="Select Project"
              sx={{ mb: "0.5rem", mr: "0.5rem", width: "15rem" }}
              value={selectedProject.name}
              variant="standard"
            >
              <MenuItem
                value="All"
                onClick={() =>
                  setSelectedProject({ _id: "", name: "", campaignPrefix: "" })
                }
              >
                All
              </MenuItem>
              {clientProjects.map(({ _id, name, campaignPrefix }) => (
                <MenuItem
                  key={_id}
                  value={name}
                  onClick={() =>
                    setSelectedProject({ _id, name, campaignPrefix })
                  }
                >
                  {name}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <>Loading...</>
          )}
          <TextField
            select
            label="Select Feedback"
            sx={{ mb: "0.5rem", mr: "0.5rem", width: "15rem" }}
            value={feedback}
            variant="standard"
          >
            <MenuItem value="All" onClick={() => setFeedback("")}>
              All
            </MenuItem>
            {feedbackTypes.map((f) => (
              <MenuItem key={f} value={f} onClick={() => setFeedback(f)}>
                {f}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box display="flex" justifyContent="end" mt={dateMarginTop}>
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              sx={{ mr: "10px", ml: "10px" }}
            >
              <Button
                variant="contained"
                onClick={handleUnassignLeads}
                sx={{ mr: "10px", backgroundColor: theme.palette.primary[500] }}
              >
                Unassign Leads
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: theme.palette.primary[500] }}
                onClick={handlePopupOpen}
              >
                Bulk Assign
              </Button>
              {teamMembers && (
                <ClientBulkAssignModal
                  open={isPopupOpen}
                  onClose={handlePopupClose}
                  teamMembers={teamMembers}
                  selectedRows={selectedRows}
                />
              )}
            </Box>
            <DatePicker
              label="Start Date"
              value={dayjs.utc(dayjs(startDate))}
              onChange={(newValue) => {
                const utcDate = dayjs(newValue).utc(true);
                setStartDate(utcDate?.toISOString() || null);
              }}
              textField={(params) => (
                <TextField
                  {...params}
                  sx={{ mb: "0.5rem", width: "15rem" }}
                  variant="standard"
                />
              )}
            />
            <DatePicker
              label="End Date"
              value={dayjs.utc(dayjs(endDate))}
              sx={{ ml: "10px" }}
              onChange={(newValue) => {
                const utcDate = dayjs(newValue).utc(true);
                setEndDate(utcDate?.toISOString() || null);
              }}
              textField={(params) => (
                <TextField
                  {...params}
                  sx={{ mb: "0.5rem", width: "15rem" }}
                  variant="standard"
                />
              )}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <Box
        mt="40px"
        height="75vh"
        width="93vw"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders .MuiSvgIcon-root": {
            color: theme.palette.secondary[100],
          },
          // "& .MuiDataGrid-virtualScroller": {
          //   backgroundColor: theme.palette.primary.light
          // },
          "& .MuiDataGrid-footerContainer": {
            // backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.primary[500]} !important`,
          },
        }}
      >
        <DataGrid
          apiRef={apiRef}
          loading={!leads}
          getRowId={(row) => row._id}
          rows={leads || []}
          columns={columns}
          rowCount={total || 0}
          pageSizeOptions={[20, 50, 100]}
          pagination
          paginationModel={{ page: page, pageSize: pageSize }}
          paginationMode="server"
          sortingMode="server"
          disableRowSelectionOnClick
          checkboxSelection
          rowSelectionModel={selectedRows}
          onCellDoubleClick={handleRowDoubleClick}
          onCellEditStop={(params) => console.log(`params: ${params}`)}
          onRowSelectionModelChange={(newSelection) =>
            setSelectedRows(newSelection)
          }
          processRowUpdate={(updatedRow, originalRow) =>
            handleFeedbackUpdate(updatedRow, originalRow)
          }
          onProcessRowUpdateError={(error) => console.log(`error: ${error}`)}
          onPaginationModelChange={(newModel) => {
            setPage(newModel.page);
            setPageSize(newModel.pageSize);
          }}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          slots={{ toolbar: DataGridCustomToolbar }}
          slotProps={{
            toolbar: {
              searchInput,
              setSearchInput,
              setSearch,
              columns,
              filename: client?.name,
              selectedRows,
            },
            cell: {
              onMouseEnter: handleCellOver,
              onMouseLeave: handleCellOut,
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default ClientLeads;
