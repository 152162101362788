import {
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
} from "@mui/x-data-grid";
import { ExportMenuItem } from "./ExportMenuItem";
import React from "react";

export function ExportExcelButton(props) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={props.csvOptions} />
      <GridPrintExportMenuItem options={props.printOptions} />
      <ExportMenuItem
        columns={props.columns}
        filename={props.filename}
        selectedRows={props.selectedRows}
      />
    </GridToolbarExportContainer>
  );
}
