import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function Unsubscribe() {
  const { clientId } = useParams();

  useEffect(() => {
    if (clientId) {
      async function unsubscribeClient(clientId) {
        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}unsubscribe/${clientId}`,
          { wantsNotifications: false }
        );
      }
      unsubscribeClient(clientId);
    }
  }, [clientId]);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h5" align="center" color="white">
        Successfully Unsubscribed from New Lead Notifications
      </Typography>
    </Box>
  );
}

export default Unsubscribe;
