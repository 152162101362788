import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const customBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const api = createApi({
  baseQuery: customBaseQuery,
  reducerPath: "adminApi",
  tagTypes: [
    "Projects",
    "SingleProject",
    "ProjectLeads",
    "Leads",
    "CampaignNames",
    "Clients",
    "SingleClient",
    "ClientLeads",
    "ClientTeamMembers",
    "Users",
    "NumberOfUnassignedLeads",
    "TodayLeads",
    "SingleLead",
    "ClientViewWeeklySummary",
    "TeamMemberLeads",
    "TeamMember",
    "TeamMemberByEmail",
    "TeamMemberFeedbackSummary",
    "SingleLeadHistory",
  ],
  endpoints: (build) => ({
    getAllProjects: build.query({
      query: () => "projectsServer/projects",
      providesTags: ["Projects"],
    }),
    getSingleProject: build.query({
      query: (id) => `projectsServer/projects/${id}`,
      providesTags: ["SingleProject"],
    }),
    getProjectLeads: build.query({
      query: ({
        projectId,
        campaignPrefix,
        page,
        pageSize,
        sort,
        search,
        campaignFilter,
        startDate,
        endDate,
      }) => ({
        url: `leadsServer/leads/projectLeads`,
        method: "GET",
        params: {
          campaignPrefix,
          page,
          pageSize,
          sort,
          search,
          campaignFilter,
          startDate,
          endDate,
          projectId,
        },
      }),
      providesTags: ["ProjectLeads"],
    }),
    getAllLeads: build.query({
      query: ({
        page,
        pageSize,
        sort,
        search,
        campaignFilter = "",
        startDate = "",
        endDate = "",
        clientFilter = "",
        feedbackFilter = "",
        campaignPrefix = "",
      }) => ({
        url: "leadsServer/leads",
        method: "GET",
        params: {
          page,
          pageSize,
          sort,
          search,
          campaignFilter,
          startDate,
          endDate,
          clientFilter,
          feedbackFilter,
          campaignPrefix,
        },
      }),
      providesTags: ["Leads"],
    }),
    getNameSearchLeads: build.query({
      query: (name) => ({
        url: "leadsServer/leads/nameSearch",
        method: "GET",
        params: { name },
      }),
    }),
    getTotalUnassignedLeads: build.query({
      query: () => "leadsServer/leads/totalUnassigned",
      providesTags: ["NumberOfUnassignedLeads"],
    }),
    getTodayLeads: build.query({
      query: () => "leadsServer/leads/todayLeads",
      providesTags: ["TodayLeads"],
    }),
    getSingleLead: build.query({
      query: (id) => `leadsServer/leads/singleLead/${id}`,
      providesTags: ["SingleLead"],
      keepUnusedDataFor: 0,
    }),
    getSingleLeadHistory: build.query({
      query: (id) => `leadsServer/leadHistory/${id}`,
      providesTags: ["SingleLeadHistory"],
    }),
    getUniqueCampaignNames: build.query({
      query: () => "leadsServer/uniqueCampaignNames",
      providesTags: ["CampaignNames"],
    }),
    getAllClients: build.query({
      query: () => "clientsServer/clients",
      providesTags: ["Clients"],
    }),
    getClientLeads: build.query({
      query: ({
        id,
        page,
        pageSize,
        sort,
        search,
        startDate,
        endDate,
        campaignPrefix,
        feedback = "",
      }) => ({
        url: `leadsServer/leads/clientLeads/${id}`,
        method: "GET",
        params: {
          page,
          pageSize,
          sort,
          search,
          startDate,
          endDate,
          campaignPrefix,
          feedback,
        },
      }),
      providesTags: ["ClientLeads"],
    }),
    getSingleClient: build.query({
      query: (id) => `clientsServer/clients/${id}`,
      providesTags: ["SingleClient"],
    }),
    getAllClientTeamMembers: build.query({
      query: (id) => `clientsServer/clients/getAllTeamMembers/${id}`,
      providesTags: ["ClientTeamMembers"],
    }),
    getTeamMemberLeads: build.query({
      query: ({
        id,
        page = 0,
        pageSize = 1000,
        sort = JSON.stringify({ field: "createdAt", sort: "desc" }),
        search = "",
        startDate = "",
        endDate = "",
        campaignPrefix = "",
        feedback = "",
      }) => ({
        url: `teamMemberServer/getTeamMemberLeads/${id}`,
        method: "GET",
        params: {
          page,
          pageSize,
          sort,
          search,
          startDate,
          endDate,
          campaignPrefix,
          feedback,
        },
      }),
      providesTags: ["TeamMemberLeads"],
    }),
    getTeamMember: build.query({
      query: (id) => `teamMemberServer/getTeamMember/${id}`,
      providesTags: ["TeamMember"],
    }),
    getTeamMemberByEmail: build.query({
      query: (email) => `teamMemberServer/getTeamMemberByEmail/${email}`,
      providesTags: ["TeamMemberByEmail"],
    }),
    getTeamMemberFeedbackSummary: build.query({
      query: (id) => `teamMemberServer/getTeamMemberFeedbackSummary/${id}`,
      providesTags: ["TeamMemberFeedbackSummary"],
    }),
    getAllUsers: build.query({
      query: () => "usersServer/allUsers",
      providesTags: ["Users"],
    }),
    getClientWeeklySummary: build.query({
      query: (id) => `clientsViewServer/weeklySummary/${id}`,
      providesTags: ["ClientViewWeeklySummary"],
    }),
  }),
});

export const {
  useGetAllProjectsQuery,
  useGetSingleProjectQuery,
  useGetProjectLeadsQuery,
  useGetAllLeadsQuery,
  useGetNameSearchLeadsQuery,
  useGetUniqueCampaignNamesQuery,
  useGetAllClientsQuery,
  useGetSingleClientQuery,
  useGetClientLeadsQuery,
  useGetAllUsersQuery,
  useGetTotalUnassignedLeadsQuery,
  useGetTodayLeadsQuery,
  useGetSingleLeadQuery,
  useGetClientWeeklySummaryQuery,
  useGetAllClientTeamMembersQuery,
  useGetTeamMemberLeadsQuery,
  useGetTeamMemberQuery,
  useGetTeamMemberByEmailQuery,
  useGetTeamMemberFeedbackSummaryQuery,
  useGetSingleLeadHistoryQuery,
} = api;
