import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function TotalLeadsProgress({ clients }) {
  const theme = useTheme();
  const [totalLeadsRequested, setTotalLeadsRequested] = useState(0);
  const [totalLeadsReceived, setTotalLeadsReceived] = useState(0);
  const [completionPercentage, setCompletionPercentage] = useState(0);

  useEffect(() => {
    let totalRequested = 0;
    let totalReceived = 0;
    clients?.forEach((client) => {
      if (client.active) {
        client.projects.forEach((project) => {
          totalRequested += project.numberLeads;
          totalReceived += project.numberLeadsReceived || 0;
        });
      }
    });

    const percentage = totalReceived / totalRequested;
    setTotalLeadsRequested(totalRequested);
    setTotalLeadsReceived(totalReceived);
    setCompletionPercentage(percentage);
  }, [clients]);

  return (
    <Box
      gridColumn="span 12"
      gridRow="span 2"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      borderRadius="0.55rem"
    >
      <Typography
        variant="h5"
        color={theme.palette.secondary[300]}
        align="center"
        sx={{ mb: "20px" }}
      >
        Lead Generation Completion Rate For Today
      </Typography>
      <CircularProgressbar
        value={completionPercentage}
        maxValue={1}
        text={`${(completionPercentage * 100).toFixed(1)}%`}
        styles={{
          path: {
            stroke: theme.palette.secondary[200],
          },
          text: {
            fill: theme.palette.secondary[200],
          },
        }}
      />
      <FlexBetween sx={{ mt: "20px", color: theme.palette.secondary[200] }}>
        <Typography sx={{ mr: "5px" }}>Clients Requested:</Typography>
        <Typography fontWeight="bold">{totalLeadsRequested} Leads</Typography>
      </FlexBetween>
      <FlexBetween sx={{ color: theme.palette.secondary[200] }}>
        <Typography sx={{ mr: "5px" }}>Clients Received:</Typography>
        <Typography fontWeight="bold">{totalLeadsReceived} Leads</Typography>
      </FlexBetween>
    </Box>
  );
}

export default TotalLeadsProgress;
