import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { useGetAllClientsQuery, useGetAllProjectsQuery } from "state/api";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

const ClientProject = ({ _id, leadsRequested, leadsReceived, allProjects }) => {
  const theme = useTheme();
  const project = allProjects.find((p) => p._id === _id);
  return (
    <Box sx={{ mb: "10px" }}>
      <Typography
        sx={{ fontSize: 14 }}
        color={theme.palette.secondary[700]}
        gutterBottom
      >
        {project.name}
      </Typography>
      <FlexBetween>
        <Typography sx={{ mr: "5px" }}>Leads Requested:</Typography>
        <Typography fontWeight="bold">{leadsRequested}</Typography>
      </FlexBetween>
      <FlexBetween>
        <Typography sx={{ mr: "5px" }}>Leads Received:</Typography>
        <Typography fontWeight="bold">{leadsReceived}</Typography>
      </FlexBetween>
    </Box>
  );
};

const Client = ({
  _id,
  name,
  description,
  country,
  email,
  phoneNumber,
  projects,
  allProjects,
  recievingLeads,
  wantsNotifications,
  active,
}) => {
  console.log("🚀 ~ name:", name);
  console.log("🚀 ~ wantsNotifications:", wantsNotifications);
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  let totalLeadsRequested = 0;
  let totalLeadsReceived = 0;

  projects.forEach((proj) => {
    totalLeadsRequested += proj.numberLeads;
    totalLeadsReceived += proj.numberLeadsReceived || 0;
  });

  const [displayRecieve, setDisplayRecieve] = useState(recievingLeads);
  const [displayNotifications, setDisplayNotifications] =
    useState(wantsNotifications);
  const [displayActive, setDisplayActive] = useState(active);

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const toggleRecievingLeads = async () => {
    const newRecieve = !displayRecieve;
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}clientsServer/clients/toggleRecieveLeads/${_id}`,
      { recievingLeads: newRecieve },
      configToken
    );
    setDisplayRecieve(!displayRecieve);
  };

  const toggleWantsNotifications = async () => {
    const newNotifications = !displayNotifications;
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}clientsServer/clients/toggleWantsNotifications/${_id}`,
      { wantsNotifications: newNotifications },
      configToken
    );
    setDisplayNotifications(!displayNotifications);
  };

  const toggleActive = async () => {
    const newActive = !displayActive;
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}clientsServer/clients/toggleActive/${_id}`,
      { active: newActive },
      configToken
    );
    setDisplayActive(newActive);
  };

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
        color: theme.palette.secondary[200],
      }}
    >
      <CardContent>
        <FlexBetween>
          <Box>
            <Typography
              sx={{ fontSize: 14 }}
              color={theme.palette.secondary[700]}
              gutterBottom
            >
              {country}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/clients/${_id}`);
              }}
            >
              {name}
            </Typography>
          </Box>
          <Box>
            <FlexBetween>
              <Typography sx={{ mr: "5px" }}>Total Leads Requested:</Typography>
              <Typography fontWeight="bold">{totalLeadsRequested}</Typography>
            </FlexBetween>
            <FlexBetween>
              <Typography sx={{ mr: "5px" }}>Total Leads Received:</Typography>
              <Typography fontWeight="bold">{totalLeadsReceived}</Typography>
            </FlexBetween>
          </Box>
        </FlexBetween>
        <Box sx={{ mt: "10px", display: "flex", flexDirection: "column" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={displayActive}
                  onChange={toggleActive}
                  color="secondary"
                />
              }
              label="Active Client"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={displayRecieve}
                  onChange={toggleRecievingLeads}
                  color="secondary"
                />
              }
              label="Automatically Assign Leads to Client"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={displayNotifications}
                  onChange={toggleWantsNotifications}
                  color="secondary"
                />
              }
              label="Recieve Notifications"
            />
          </FormGroup>
        </Box>
      </CardContent>
      <CardActions>
        <Button
          variant="primary"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "See Less" : "See More"}
        </Button>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Typography
            sx={{ fontSize: 14 }}
            color={theme.palette.secondary[700]}
            gutterBottom
          >
            {email} | {phoneNumber}
          </Typography>
          {projects.map((proj) => (
            <ClientProject
              key={proj.project}
              _id={proj.project}
              leadsRequested={proj.numberLeads}
              leadsReceived={proj.numberLeadsReceived}
              allProjects={allProjects}
            />
          ))}
          <Typography variant="body2">{description}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

function Clients() {
  const theme = useTheme();
  const { data, isLoading } = useGetAllClientsQuery();
  console.log("🚀 ~ Clients ~ data:", data);
  const { data: allProjects } = useGetAllProjectsQuery();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate();

  const [searchClients, setSearchClients] = useState("");

  // Filter clients based on the search query
  const filteredClients = data
    ? data.filter((client) =>
        client.name.toLowerCase().startsWith(searchClients.toLowerCase())
      )
    : [];

  // Make it mobile responsive
  const flexDirection = isNonMobile ? "row" : "column";
  const alignItems = isNonMobile ? "center" : "";

  return (
    <Box m="1.5rem 2.5rem">
      <Box
        display="flex"
        flexDirection={flexDirection}
        justifyContent="space-between"
        alignItems={alignItems}
      >
        <Header title="CLIENTS" subtitle="See all clients." />
        <Box>
          <TextField
            sx={{ mb: "0.5rem", width: "15rem", mr: "3rem" }}
            label="Search Clients..."
            variant="standard"
            value={searchClients}
            onChange={(e) => setSearchClients(e.target.value)}
          />
          <Button
            onClick={() => navigate("/clients/create")}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Add New Client
          </Button>
        </Box>
      </Box>
      {data ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {filteredClients
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
            .map(
              ({
                _id,
                name,
                description,
                country,
                email,
                phoneNumber,
                projects,
                recievingLeads,
                wantsNotifications,
                active,
              }) => (
                <Client
                  key={_id}
                  _id={_id}
                  name={name}
                  description={description}
                  email={email}
                  country={country}
                  phoneNumber={phoneNumber}
                  projects={projects}
                  allProjects={allProjects}
                  recievingLeads={recievingLeads}
                  wantsNotifications={wantsNotifications}
                  active={active}
                />
              )
            )}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
}

export default Clients;
