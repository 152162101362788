import { Box, useTheme, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { useGetAllClientTeamMembersQuery } from "state/api";

function TeamMembersTable({ client }) {
  const theme = useTheme();
  const { data } = useGetAllClientTeamMembersQuery(client?._id);
  console.log("🚀 ~ TeamMembersTable ~ data:", data);

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleDelete = async (_id) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}teamMemberServer/deleteTeamMember/${_id}`,
      configToken
    );
    if (response.status === 200) {
      window.location.reload();
    }
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 0.7,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.4,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.7,
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.1,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row._id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
  return (
    <Box
      // mt="40px"
      height="100%"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid": {
          borderBottom: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderBottom: "none",
        },
        "& .MuiDataGrid-columnHeaders .MuiSvgIcon-root": {
          color: theme.palette.secondary[100],
        },
        // "& .MuiDataGrid-virtualScroller": {
        //   backgroundColor: theme.palette.primary.light
        // },
        "& .MuiDataGrid-footerContainer": {
          // backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderTop: "none",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${theme.palette.primary[500]} !important`,
        },
      }}
    >
      <DataGrid
        loading={!data}
        getRowId={(row) => row._id}
        rows={data?.teamMembers || []}
        columns={columns}
        disableRowSelectionOnClick
      />
    </Box>
  );
}

export default TeamMembersTable;
