import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { useSelector } from "react-redux";

function BulkAssign({ selectedRows, clients, open, onClose }) {
  const [inputRows, setInputRows] = useState([
    { clientId: "", numLeads: "", clientName: "" },
  ]);
  const [leadsRemaining, setLeadsRemaining] = useState(selectedRows.length);

  useEffect(() => {
    let usedLeads = 0;
    inputRows.forEach((row) => (usedLeads += Number(row.numLeads)));
    setLeadsRemaining(selectedRows.length - usedLeads);
  }, [inputRows, selectedRows]);

  const handleAddRow = () => {
    setInputRows([
      ...inputRows,
      { clientId: "", numLeads: "", clientName: "" },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...inputRows];
    updatedRows[index][field] = value;
    setInputRows(updatedRows);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = inputRows.filter((_, i) => index !== i);
    setInputRows(updatedRows);
  };

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (leadsRemaining >= 0) {
      let lastIndex = 0;
      for (const row of inputRows) {
        const allLeads = selectedRows.slice(
          lastIndex,
          lastIndex + Number(row.numLeads)
        );
        for (const lead of allLeads) {
          const leadId = lead;
          const clientId = row.clientId;
          try {
            const response = await axios.put(
              `${process.env.REACT_APP_BASE_URL}leadsServer/leads/${leadId}/client/${clientId}`,
              {},
              configToken
            );
            if (response.status === 200) {
              console.log(
                "Successfully assigned lead to client",
                response.data
              );
            }
          } catch (error) {
            console.log("Error assigning lead to client", error);
          }
        }
        lastIndex = lastIndex + Number(row.numLeads);
      }
      onClose();
      window.location.reload();
    }
  };

  const leadsRemainingColor = leadsRemaining < 0 ? "red" : "";

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <FlexBetween>
          <Typography variant="h6">Bulk Assign Leads</Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </FlexBetween>
        <Typography color={leadsRemainingColor}>
          Leads to Assign: {leadsRemaining}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {inputRows.map((row, index) => (
              <Box key={index}>
                <FlexBetween>
                  <TextField
                    label="Number of Leads"
                    value={row.numLeads}
                    onChange={(e) =>
                      handleInputChange(index, "numLeads", e.target.value)
                    }
                    fullWidth
                    required
                  />
                  <FormControl fullWidth required>
                    <InputLabel id="client-select-label">
                      Select Client
                    </InputLabel>
                    <Select
                      value={row.clientName}
                      labelId="client-select-label"
                    >
                      {clients ? (
                        clients
                          .slice()
                          .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
                          .map((client) => (
                            <MenuItem
                              key={client._id}
                              value={client.name}
                              onClick={() => {
                                handleInputChange(
                                  index,
                                  "clientId",
                                  client._id
                                );
                                handleInputChange(
                                  index,
                                  "clientName",
                                  client.name
                                );
                              }}
                            >
                              {client.name}
                            </MenuItem>
                          ))
                      ) : (
                        <MenuItem value="" disabled>
                          Loading...
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <IconButton onClick={() => handleRemoveRow(index)}>
                    <DeleteIcon />
                  </IconButton>
                </FlexBetween>
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Button
              onClick={handleAddRow}
              variant="outlined"
              sx={{ mt: "10px" }}
            >
              + Add Client
            </Button>

            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default BulkAssign;
