import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
  Typography,
} from "@mui/material";
import axios from "axios";
import { SelectComponentBorder } from "components/styles/customstyles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function EditStatusFeedback({ lead }) {
  const { id } = useParams();
  const theme = useTheme();

  const [status, setStatus] = useState(lead?.status || "");
  const [feedback, setFeedback] = useState(lead?.feedback || "");
  const [writtenFeedback, setWrittenFeedback] = useState(
    lead?.writtenFeedback || ""
  );

  useEffect(() => {
    console.log("Lead.name", lead.name);
    console.log("Lead.feedback", lead.feedback);
    setStatus(lead?.status || "");
    setFeedback(lead?.feedback || "");
    setWrittenFeedback(lead?.writtenFeedback || "");
  }, [lead]);

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const statusOptions = [
    "Attempted First Call",
    "Followed Up",
    "Meeting Set",
    "Closed Deal",
    "",
  ];

  const feedbackOptions = [
    "No Answer",
    "Interested",
    "Not Interested",
    "Switched Off",
    "Call Back",
    "Duplicate",
    "Follow Up",
    "Wrong Number",
    "Low Budget",
    "",
  ];

  const handleStatusChange = async (event) => {
    setStatus(event.target.value);
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editStatus/${id}`,
      {
        status: event.target.value,
      },
      configToken
    );
  };

  const handleFeedbackChange = async (event) => {
    setFeedback(event.target.value);
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editFeedback/${id}`,
      {
        feedback: event.target.value,
      },
      configToken
    );
  };

  const handleWrittenFeedbackChange = async () => {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editFeedback/${id}`,
      {
        writtenFeedback: writtenFeedback,
      },
      configToken
    );
  };

  return (
    <Box
      gridColumn="span 12"
      gridRow="span 7"
      display="flex"
      gap="10px"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      color={theme.palette.secondary[300]}
      borderRadius="0.55rem"
    >
      <Typography variant="h5" color={theme.palette.secondary[300]}>
        Edit Status, Feedback, and Notes
      </Typography>
      {/* Lead Status */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap="10px"
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel
            id="status-label"
            sx={{ color: theme.palette.secondary[300] }}
          >
            Status
          </InputLabel>
          <Select
            labelId="status-label"
            id="status-select"
            value={status}
            onChange={handleStatusChange}
            label="Status"
            sx={SelectComponentBorder(theme.palette.secondary[200])}
          >
            {statusOptions.map((stat, index) => (
              <MenuItem key={index} value={stat}>
                {stat}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Lead Feedback */}

        <FormControl fullWidth variant="outlined">
          <InputLabel
            id="status-label"
            sx={{ color: theme.palette.secondary[300] }}
          >
            Feedback
          </InputLabel>
          <Select
            labelId="feedback-label"
            id="feedback-select"
            value={feedback}
            onChange={handleFeedbackChange}
            label="Feedback"
            sx={SelectComponentBorder(theme.palette.secondary[200])}
          >
            {feedbackOptions.map((fb, index) => (
              <MenuItem key={index} value={fb}>
                {fb}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Lead Written Feedback */}
      <Box>
        <TextField
          fullWidth
          id="notes"
          label="Notes"
          variant="outlined"
          value={writtenFeedback}
          onChange={(e) => setWrittenFeedback(e.target.value)}
          InputLabelProps={{
            sx: { color: theme.palette.secondary[200] }, // Set the label color to black
          }}
          InputProps={{
            sx: {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.secondary[200], // Set the outline color to black
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.secondary[200], // Set the outline color to black on hover
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.secondary[200], // Set the outline color to black when focused
              },
              "& .MuiInputBase-input": {
                color: theme.palette.secondary[200], // Set the input text color to black
              },
            },
          }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.secondary[200], // Set the outline color to black
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.secondary[200], // Set the outline color to black on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.secondary[200], // Set the outline color to black when focused
            },
            color: theme.palette.secondary[200],
          }}
        />
        <Button
          onClick={handleWrittenFeedbackChange}
          variant="contained"
          sx={{ mt: "10px", mb: "10px" }}
        >
          Save Notes
        </Button>
      </Box>
    </Box>
  );
}

export default EditStatusFeedback;
