import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useLogout } from "state/hooks/useLogout";

// Import Dashboard and Layout
import Dashboard from "pages/dashboard/Dashboard";
import Layout from "pages/Layout";

// import Project Pages
import Projects from "pages/projects/Projects";
import CreateProject from "pages/projects/CreateProject";
import SingleProject from "pages/projects/SingleProject";
import UpdateProject from "pages/projects/UpdateProject";

// import Lead Pages
import Leads from "pages/leads/Leads";
import SingleLead from "pages/leads/SingleLead";

// import Client Pages
import Clients from "pages/clients/Clients";
import CreateClient from "pages/clients/CreateClient";
import SingleClient from "pages/clients/SingleClient";
import UpdateClient from "pages/clients/UpdateClient";

// import Settings Pages
import Settings from "pages/settings/Settings";

// import Login Page
import Login from "pages/Login";

// import pages for Clients Only
import ClientDashboard from "pages/clientPages/ClientDashboard";
import ClientLeads from "pages/clientPages/ClientLeads";
import ClientSettings from "pages/clientPages/settings/ClientSettings";
import ClientTeamMembers from "pages/clientPages/teamMembers/ClientTeamMembers";
import SingleTeamMember from "pages/clientPages/teamMembers/SingleTeamMember";
import TeamMemberDashboard from "pages/teamMemberPages/TeamMemberDashboard";
import TeamMemberLeads from "pages/teamMemberPages/TeamMemberLeads";
import Unsubscribe from "pages/Unsubscribe";
import CreateTeamMember from "pages/clientPages/teamMembers/CreateTeamMember";

function App() {
  // useSelector is provided by react-redux and allows us to access the global state
  const mode = useSelector((state) => state.global.mode);
  // useMemo is used to optimize expensive computations by not doing them again unless the [mode] value changes
  // createTheme customizes the Material UI theme based on the themeSettings we provide
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const user = useSelector((state) => state.auth.user);

  const { logout } = useLogout();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.token) {
      const decodedToken = jwt_decode(user.token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        // Token has expired, redirect to login page
        logout();
      }
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            {/* Handle unsubscribe from notifications */}
            <Route path="/unsubscribe/:clientId" element={<Unsubscribe />} />
            {/* Redirect to appropriate route based on user */}
            {user ? (
              <Route path="/login" element={<Navigate to="/dashboard" />} />
            ) : (
              <Route path="/login" element={<Login />} />
            )}

            {user ? (
              <Route path="/" element={<Navigate to="/dashboard" />} />
            ) : (
              <Route path="/*" element={<Navigate to="/login" />} />
            )}

            {/* Admin Routes */}
            {user && user.role === "Admin" && (
              <Route element={<Layout />}>
                {/* Dashboard Routes */}
                <Route
                  path="/"
                  element={<Navigate to="/dashboard" replace />}
                />
                <Route path="/dashboard" element={<Dashboard />} />

                {/* Project Routes */}
                <Route path="/projects" element={<Projects />} />
                <Route path="/projects/create" element={<CreateProject />} />
                <Route path="/projects/:id" element={<SingleProject />} />
                <Route
                  path="/projects/update/:id"
                  element={<UpdateProject />}
                />

                {/* Client Routes */}
                <Route path="/clients" element={<Clients />} />
                <Route path="/clients/create" element={<CreateClient />} />
                <Route path="/clients/:id" element={<SingleClient />} />
                <Route path="/clients/update/:id" element={<UpdateClient />} />

                {/* Lead Routes */}
                <Route path="/leads" element={<Leads />} />
                <Route path="/leads/:id" element={<SingleLead />} />

                {/* Settings Routes */}
                <Route path="/settings" element={<Settings />} />
              </Route>
            )}

            {/* Client Routes */}
            {user && user.role === "Client" && (
              <Route element={<Layout />}>
                {/* Client Dashboard */}
                <Route
                  path="/"
                  element={<Navigate to="/clientdashboard" replace />}
                />
                <Route
                  path="/dashboard"
                  element={<Navigate to="/clientdashboard" replace />}
                />
                <Route path="/clientdashboard" element={<ClientDashboard />} />

                {/* Client Leads */}
                <Route
                  path="/leads"
                  element={<Navigate to="/clientleads" replace />}
                />
                <Route path="/clientleads" element={<ClientLeads />} />
                <Route path="/leads/:id" element={<SingleLead />} />

                {/* Handle Other Paths */}
                <Route
                  path="/*"
                  element={<Navigate to="/clientdashboard" replace />}
                />

                {/* Client Settings */}
                <Route
                  path="/settings"
                  element={<Navigate to="/clientsettings" replace />}
                />
                <Route path="/clientsettings" element={<ClientSettings />} />

                {/* Client Team Members */}
                <Route path="/teammembers" element={<ClientTeamMembers />} />
                <Route path="/teammembers/:id" element={<SingleTeamMember />} />
                <Route
                  path="/teammembers/create"
                  element={<CreateTeamMember />}
                />
              </Route>
            )}

            {/* Team Member Routes */}
            {user && user.role === "Team Member" && (
              <Route element={<Layout />}>
                {/* Client Dashboard */}
                <Route
                  path="/"
                  element={<Navigate to="/teammemberdashboard" replace />}
                />
                <Route
                  path="/dashboard"
                  element={<Navigate to="/teammemberdashboard" replace />}
                />
                <Route
                  path="/teammemberdashboard"
                  element={<TeamMemberDashboard />}
                />

                {/* Client Leads */}
                <Route
                  path="/leads"
                  element={<Navigate to="/teammemberleads" replace />}
                />
                <Route path="/teammemberleads" element={<TeamMemberLeads />} />
                <Route path="/leads/:id" element={<SingleLead />} />

                {/* Handle Other Paths */}
                <Route
                  path="/*"
                  element={<Navigate to="/teammemberdashboard" replace />}
                />
              </Route>
            )}
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
