import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function ClientLeadCompletion({ client, leadData, weekly }) {
  const theme = useTheme();
  const [totalLeadsRequested, setTotalLeadsRequested] = useState(0);
  const [totalLeadsReceived, setTotalLeadsReceived] = useState(0);
  const [completionPercentage, setCompletionPercentage] = useState(0);

  useEffect(() => {
    let totalRequested = 0;
    client?.projects.forEach((project) => {
      totalRequested += project.numberLeads;
    });

    if (weekly == "week") {
      totalRequested = totalRequested * 7;
    }

    const percentage = leadData?.total / totalRequested;
    setTotalLeadsRequested(totalRequested);
    setTotalLeadsReceived(leadData?.total || 0);
    setCompletionPercentage(percentage);
  }, [client, leadData]);

  return (
    <Box
      gridColumn="span 6"
      gridRow="span 6"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      borderRadius="0.55rem"
    >
      <Typography
        variant="h5"
        color={theme.palette.secondary[300]}
        align="center"
        sx={{ mb: "20px" }}
      >
        Lead Generation Completion Rate For{" "}
        {weekly == "week" ? "This Week" : "Today"}
      </Typography>
      <CircularProgressbar
        value={completionPercentage}
        maxValue={1}
        text={`${(completionPercentage * 100).toFixed(1)}%`}
        styles={{
          path: {
            stroke: theme.palette.secondary[200],
          },
          text: {
            fill: theme.palette.secondary[200],
          },
        }}
      />
      <FlexBetween sx={{ mt: "20px" }} color={theme.palette.secondary[300]}>
        <Typography sx={{ mr: "5px" }}>You Requested:</Typography>
        <Typography fontWeight="bold">{totalLeadsRequested} Leads</Typography>
      </FlexBetween>
      <FlexBetween color={theme.palette.secondary[300]}>
        <Typography sx={{ mr: "5px" }}>You Received:</Typography>
        <Typography fontWeight="bold">{totalLeadsReceived} Leads</Typography>
      </FlexBetween>
    </Box>
  );
}

export default ClientLeadCompletion;
