import { useSelector } from "react-redux";

const useStoreExistingFilters = () => {
  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const storeExistingFilters = (filters) => {
    // store the filters in local storage to keep them
    console.log("filters: ", filters);
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  // Function to get filter values and remove them from localStorage
  const getAndRemoveFilters = () => {
    const storedFilters = localStorage.getItem("filters");

    if (storedFilters !== "undefined") {
      // Parse the filters
      const filters = JSON.parse(storedFilters);
      console.log("🚀 ~ getAndRemoveFilters ~ filters:", filters);

      // Remove the filters from localStorage
      localStorage.removeItem("filters");

      return filters;
    }

    return;
  };

  return { storeExistingFilters, getAndRemoveFilters };
};

export default useStoreExistingFilters;
