import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import Header from "components/Header";
import SectionHeader from "../../components/SectionHeader";
import AddAdminUser from "./AddAdminUser";
import UserTable from "./UserTable";

function Settings() {
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="SETTINGS" subtitle="Manage Phieg CRM Settings" />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: "20px" }}
      >
        <Box sx={{ mt: "20px", width: "40%" }}>
          <SectionHeader title="ADD NEW ADMIN USER" />
          <AddAdminUser />
        </Box>
        <Box sx={{ mt: "20px", width: "60%" }}>
          <SectionHeader title="LIST OF ALL USERS" />
          <UserTable />
        </Box>
      </Box>
    </Box>
  );
}

export default Settings;
