import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  GridViewOutlined,
  VillaOutlined,
  PersonOutlined,
  Groups2Outlined,
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
} from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import { useSelector } from "react-redux";
import logoInverted from "../phieg-logo-inverted.png";
import logoNormal from "../phieg-logo.png";

function Sidebar({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) {
  const { pathname } = useLocation(); // gives us out current location
  const [active, setActive] = useState(""); // will be used to keep track of what page we are currently at
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const currentPage = pathname.split("/")[1];
    if (currentPage.startsWith("client")) {
      setActive(currentPage.split("client")[1]);
    } else if (currentPage.startsWith("teammember")) {
      if (currentPage === "teammembers") {
        setActive(currentPage);
      } else {
        setActive(currentPage.split("teammember")[1]);
      }
    } else {
      setActive(currentPage);
    }
  }, [pathname]);

  const [navItems, setNavItems] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const mode = useSelector((state) => state.global.mode);

  const logo = mode == "dark" ? logoNormal : logoInverted;

  useEffect(() => {
    const role = user ? user?.role : "";

    if (role === "Admin") {
      setNavItems([
        {
          text: "Dashboard",
          icon: <GridViewOutlined />,
        },
        {
          text: "Client Management",
          icon: null,
        },
        {
          text: "Projects",
          icon: <VillaOutlined />,
        },
        {
          text: "Clients",
          icon: <PersonOutlined />,
        },
        {
          text: "Leads",
          icon: <Groups2Outlined />,
        },
        {
          text: "Admin",
          icon: null,
        },
        {
          text: "Settings",
          icon: <SettingsOutlined />,
        },
      ]);
    } else if (role == "Client") {
      setNavItems([
        {
          text: "Dashboard",
          icon: <GridViewOutlined />,
        },
        {
          text: "Team Management",
          icon: null,
        },
        {
          text: "Leads",
          icon: <Groups2Outlined />,
        },
        {
          text: "Team Members",
          icon: <PersonOutlined />,
        },
        {
          text: "Admin",
          icon: null,
        },
        {
          text: "Settings",
          icon: <SettingsOutlined />,
        },
      ]);
    } else if (role == "Team Member") {
      setNavItems([
        {
          text: "Dashboard",
          icon: <GridViewOutlined />,
        },
        // {
        //   text: "Team Management",
        //   icon: null,
        // },
        {
          text: "Leads",
          icon: <Groups2Outlined />,
        },
        // {
        //   text: "Team Members",
        //   icon: <PersonOutlined />,
        // },
        // {
        //   text: "Admin",
        //   icon: null,
        // },
        // {
        //   text: "Settings",
        //   icon: <SettingsOutlined />,
        // },
      ]);
    }
  }, [user]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Link to="/">
                    <img
                      src={logo}
                      alt="Phieg Logo"
                      style={{ height: "40px", width: "auto" }}
                    />
                  </Link>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 1.5rem" }}>
                      {text}
                    </Typography>
                  );
                } else {
                  const lcText = text.toLowerCase().replace(/\s+/g, "");
                  return (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          navigate(`/${lcText}`);
                          setActive(lcText);
                          setIsSidebarOpen(false);
                        }}
                        sx={{
                          backgroundColor:
                            active === lcText
                              ? theme.palette.primary[50]
                              : "transparent",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[100],
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "0.25rem",
                            color:
                              active === lcText
                                ? theme.palette.primary[600]
                                : theme.palette.secondary[200],
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {active === lcText && (
                          <ChevronRightOutlined sx={{ ml: "auto" }} />
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                }
              })}
            </List>
          </Box>

          {/* <Box position="absolute" bottom="2rem" width="100%">
            <Divider />
            <FlexBetween textTransform="none" m="0.1rem"> */}
          {/* <Box
                component="img"
                alt='profile'
                src={profileImage}
                height="40px"
                width="40px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              /> */}
          {/* <Typography fontWeight="bold" fontSize="0.9rem" sx={{ color: theme.palette.secondary[100]}}>
                {user.name}
              </Typography>
              <Typography fontSize="0.8rem" sx={{ color: theme.palette.secondary[200]}}>
                {user.occupation}
              </Typography> */}
          {/* <SettingsOutlined 
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              /> */}
          {/* </FlexBetween>
          </Box> */}
        </Drawer>
      )}
    </Box>
  );
}

export default Sidebar;
