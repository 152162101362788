import {
  Autocomplete,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Box,
  useTheme,
  DialogContent,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FlexBetween from "components/FlexBetween";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactThreeToggle from "react-three-toggle";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import CustomDatePicker from "components/DatePicker";

const TextInput = ({ state, text, setState }) => {
  return (
    <TextField
      fullWidth
      label={text}
      value={state}
      onChange={(e) => setState(e.target.value)}
    />
  );
};

const AutocompleteInput = ({ options, text, state, setState }) => {
  return (
    <Autocomplete
      fullWidth
      options={options || []}
      value={state}
      onChange={(e, value) => setState(value)}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderInput={(params) => <TextField {...params} label={text} />}
    />
  );
};

function CreateLead({ open, onClose, allProjects, allClients }) {
  const theme = useTheme();

  const assignmentOptions = [
    "Automatically Assign Lead",
    "Manually Assign Lead",
    "Do Not Assign Lead",
  ];
  const [assignment, setAssignment] = useState("Automatically Assign Lead");
  const [name, setName] = useState("");
  const [phoneNumberOne, setPhoneNumberOne] = useState("");
  const [phoneNumberTwo, setPhoneNumberTwo] = useState("");
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [source, setSource] = useState("Manual");
  const [client, setClient] = useState(null);
  const [project, setProject] = useState(null);

  dayjs.extend(utc);
  const today = dayjs().utc(true).toISOString();
  const [createdDate, setCreatedDate] = useState(today);

  const [projects, setProjects] = useState({});
  const [clients, setClients] = useState({});

  useEffect(() => {
    if (allProjects && allClients) {
      const newProjects = allProjects
        .map((obj) => ({
          ...obj,
          label: obj.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      const newClients = allClients
        .map((obj) => ({
          ...obj,
          label: obj.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setProjects(newProjects);
      setClients(newClients);
    }
  }, [allProjects, allClients]);

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleSubmitLead = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}leadsServer/leads/createLead`,
        {
          campaignName: project?.campaignPrefix,
          clientId: client?._id,
          projectId: project?._id,
          name,
          phoneNumberOne,
          phoneNumberTwo,
          email,
          jobTitle,
          source,
          assignmentType: assignment,
          createdAt: createdDate,
        },
        configToken
      );

      if (response.status == 200) {
        console.log("Successfully created lead.");
      }

      onClose();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <FlexBetween>
          <Typography variant="h6">Create New Lead</Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </FlexBetween>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          onSubmit={(e) => handleSubmitLead(e)}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <TextInput text="Full Name" state={name} setState={setName} />
          <TextInput
            text="Phone Number One"
            state={phoneNumberOne}
            setState={setPhoneNumberOne}
          />
          <TextInput
            text="Phone Number Two"
            state={phoneNumberTwo}
            setState={setPhoneNumberTwo}
          />
          <TextInput text="Email" state={email} setState={setEmail} />
          <TextInput text="Job Title" state={jobTitle} setState={setJobTitle} />
          {projects && (
            <AutocompleteInput
              options={projects}
              text="Select Project"
              state={project}
              setState={setProject}
            />
          )}
          <CustomDatePicker
            label="Created At Date"
            state={createdDate}
            setState={setCreatedDate}
          />
          <FormControl fullWidth>
            <InputLabel id="lead-source">Lead Source</InputLabel>
            <Select
              labelId="lead-source"
              value={source}
              label="Lead Source"
              onChange={(e) => setSource(e.target.value)}
            >
              <MenuItem value="Manual">Manual</MenuItem>
              <MenuItem value="Facebook">Facebook</MenuItem>
              <MenuItem value="TikTok">TikTok</MenuItem>
              <MenuItem value="Like">Like</MenuItem>
              <MenuItem value="Comment">Comment</MenuItem>
            </Select>
          </FormControl>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <ReactThreeToggle
              values={assignmentOptions}
              onChange={(value) => setAssignment(value)}
            />
            <Typography>{assignment}</Typography>
          </Box>
          {clients && assignment == "Manually Assign Lead" && (
            <AutocompleteInput
              options={clients}
              text="Select Client"
              state={client}
              setState={setClient}
            />
          )}
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CreateLead;
