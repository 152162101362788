import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSingleProjectQuery, useGetProjectLeadsQuery } from "state/api";
import { useTheme, Box, Button, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { SourceMap } from "constants/ValueMaps";

function SingleProject() {
  const theme = useTheme();
  const { id } = useParams();
  const { data } = useGetSingleProjectQuery(id);
  const navigate = useNavigate();

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });
  const [search, setSearch] = useState("");

  dayjs.extend(utc);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const [isPointer, setIsPointer] = useState(false);

  const { data: leads, isLoading: loadingLeads } = useGetProjectLeadsQuery({
    projectId: id ? id : "",
    campaignPrefix: data?.campaignPrefix.toLowerCase() || "",
    page,
    pageSize,
    sort: JSON.stringify(sort ? sort : { field: "createdAt", sort: "desc" }),
    search,
    startDate,
    endDate,
  });

  async function handleDeleteProject() {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}projectsServer/projects/delete/${id}`,
        configToken
      );
      navigate("/projects");
      window.location.reload();
    } catch (error) {
      console.log("Error: ", error);
    }
  }

  const handleFeedbackUpdate = async (updatedRow) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editFeedback/${updatedRow._id}`,
        {
          feedback: updatedRow.feedback,
          writtenFeedback: updatedRow.writtenFeedback,
        },
        configToken
      );
      return response.data.lead;
    } catch (error) {
      console.log("🚀 ~ handleFeedbackUpdate ~ error:", error);
    }
  };

  const handleRowDoubleClick = async (params) => {
    console.log(params);
    if (params.field === "name") {
      const rowId = params.row._id;
      navigate(`/leads/${rowId}`);
    }
  };

  const handleCellOver = (event) => {
    const field = event.currentTarget.dataset.field;
    if (field === "name") {
      setIsPointer(true);
    }
  };

  const handleCellOut = (event) => {
    const field = event.currentTarget.dataset.field;
    if (field === "name") {
      setIsPointer(false);
    }
  };

  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 0.2,
    // },
    {
      field: "createdAt",
      headerName: "Date",
      // flex: 0.3,
      width: 90,
      valueFormatter: (params) => {
        const date = new Date(params.value);

        // Get the local time components
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are 0-based
        const year = date.getFullYear();

        // Create the formatted date string
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
      },
    },
    {
      field: "source",
      headerName: "Source",
      // flex: 0.25,
      width: 30,
      valueFormatter: (params) => {
        return SourceMap[params.value];
      },
    },
    {
      field: "campaignName",
      headerName: "Campaign Name",
      // flex: 0.4,
      width: 150,
    },
    {
      field: "name",
      headerName: "Full Name",
      // flex: 0.5,
      width: 150,
      renderCell: (props) => (
        <div
          style={{
            cursor: isPointer ? "pointer" : "default",
          }}
        >
          {props.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      // flex: 0.4,
      width: 150,
    },
    {
      field: "phoneNumberOne",
      headerName: "Phone Number 1",
      // flex: 0.5,
      width: 150,
    },
    {
      field: "phoneNumberTwo",
      headerName: "Phone Number 2",
      // flex: 0.5,
      width: 150,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      // flex: 0.3,
      width: 150,
    },
    {
      field: "leadCreationComments",
      headerName: "Automatically Assigned?",
      width: 200,
    },
    {
      field: "clientName",
      headerName: "Client",
      // flex: 0.4,
      width: 150,
    },
    {
      field: "feedback",
      headerName: "Feedback",
      // flex: 0.4,
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "No Answer",
        "Interested",
        "Not Interested",
        "Switched Off",
        "Call Back",
        "Duplicate",
        "Follow Up",
        "Wrong Number",
        "Low Budget",
        "",
      ],
    },
    {
      field: "writtenFeedback",
      headerName: "Notes",
      // flex: 0.4,
      width: 150,
      editable: true,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        {data && (
          <Header
            title={data.name}
            subtitle={`In Depth Information on ${data.name}`}
          />
        )}
        <Box>
          <Button
            onClick={() => navigate(`/projects/update/${id}`)}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Edit Project
          </Button>
          <Button
            onClick={() => handleDeleteProject()}
            sx={{
              backgroundColor: "#9e1b32",
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              ml: "10px",
            }}
          >
            Delete Project
          </Button>
        </Box>
      </FlexBetween>
      <Box mt="40px">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box display="flex" justifyContent="end">
            <DatePicker
              label="Start Date"
              value={dayjs.utc(dayjs(startDate))}
              onChange={(newValue) => {
                const utcDate = dayjs(newValue).utc(true);
                setStartDate(utcDate?.toISOString() || null);
              }}
              textField={(params) => (
                <TextField
                  {...params}
                  sx={{ mb: "0.5rem", width: "15rem" }}
                  variant="standard"
                />
              )}
            />
            <DatePicker
              label="End Date"
              value={dayjs.utc(dayjs(endDate))}
              sx={{ ml: "10px" }}
              onChange={(newValue) => {
                const utcDate = dayjs(newValue).utc(true);
                setEndDate(utcDate?.toISOString() || null);
              }}
              textField={(params) => (
                <TextField
                  {...params}
                  sx={{ mb: "0.5rem", width: "15rem" }}
                  variant="standard"
                />
              )}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <Box
        height="75vh"
        width="93vw"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders .MuiSvgIcon-root": {
            color: theme.palette.secondary[100],
          },
          // "& .MuiDataGrid-virtualScroller": {
          //   backgroundColor: theme.palette.primary.light
          // },
          "& .MuiDataGrid-footerContainer": {
            // backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.primary[500]} !important`,
          },
        }}
      >
        <DataGrid
          loading={loadingLeads || !leads}
          getRowId={(row) => row._id}
          rows={(leads && leads.leads) || []}
          columns={columns}
          rowCount={(leads && leads.total) || 0}
          pageSizeOptions={[20, 50, 100]}
          pagination
          paginationModel={{ page: page, pageSize: pageSize }}
          paginationMode="server"
          sortingMode="server"
          onCellDoubleClick={handleRowDoubleClick}
          onPaginationModelChange={(newModel) => {
            setPage(newModel.page);
            setPageSize(newModel.pageSize);
          }}
          processRowUpdate={(updatedRow, originalRow) =>
            handleFeedbackUpdate(updatedRow)
          }
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          slots={{ toolbar: DataGridCustomToolbar }}
          slotProps={{
            toolbar: {
              searchInput,
              setSearchInput,
              setSearch,
              columns,
              filename: data?.name,
            },
            cell: {
              onMouseEnter: handleCellOver,
              onMouseLeave: handleCellOut,
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default SingleProject;
