import React, { useEffect, useState } from "react";
import { Box, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { useGetAllProjectsQuery, useGetSingleProjectQuery } from "state/api";
import { useSelector } from "react-redux";
import axios from "axios";

const LeadInformationField = ({
  field,
  displayName,
  lead,
  isEditing,
  editedText,
  setEditedText,
  handleSubmitEdit,
  handleEditDoubleClick,
}) => {
  return (
    <FlexBetween>
      <Typography sx={{ mr: "5px" }}>{`${displayName}: `}</Typography>
      {isEditing == field ? (
        <TextField
          value={editedText}
          onChange={(e) => setEditedText(e.target.value)}
          onKeyDown={(e) => handleSubmitEdit(field, e)}
        />
      ) : (
        <Typography
          fontWeight="bold"
          onDoubleClick={() => handleEditDoubleClick(field)}
        >
          {lead[field]}
        </Typography>
      )}
    </FlexBetween>
  );
};

function LeadInformationCard({ lead, setLead }) {
  const theme = useTheme();
  const { data: project } = useGetSingleProjectQuery(lead.projectId);
  const { data: allProjects } = useGetAllProjectsQuery();
  const user = useSelector((state) => state.auth.user);

  const [isEditing, setIsEditing] = useState("");
  const [editedText, setEditedText] = useState("");

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleDoubleClick = async (phoneNumber) => {
    window.open(`tel:${phoneNumber}`, "_self");

    if (!lead.status) {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editStatus/${lead._id}`,
        {
          status: "Attempted First Call",
        },
        configToken
      );
    } else {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}leadsServer/leadHistory/createDoc/${lead._id}`,
        {
          type: "call",
          info: `${user?.name} called lead.`,
        },
        configToken
      );
    }
  };

  const handleEditDoubleClick = (field) => {
    if (user.role == "Admin") {
      setIsEditing(field);
      setEditedText(lead[field]);
    }
  };

  const handleSubmitEdit = async (field, event) => {
    if (event.key == "Enter") {
      try {
        const editedLead = { ...lead, [field]: event.target.value };

        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}leadsServer/leads/updateLead`,
          {
            updatedLead: editedLead,
          },
          configToken
        );

        if (response.status == 200) {
          setLead(editedLead);
        }

        setIsEditing("");
      } catch (error) {
        console.log(`Unable to edit lead: ${error}`);
      }
    }
  };

  // The following handles if the project is double clicked
  const [editProject, setEditProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState({
    _id: project?._id,
    name: project?.name,
  });

  useEffect(() => {
    setSelectedProject({
      _id: project?._id,
      name: project?.name,
    });
  }, [project]);

  const handleProjectDoubleClick = () => {
    if (user.role == "Admin") {
      setEditProject(true);
    }
  };

  const handleProjectChange = async ({ _id, name }) => {
    try {
      const editedLead = { ...lead, projectId: _id };

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}leadsServer/leads/updateLead`,
        {
          updatedLead: editedLead,
        },
        configToken
      );

      if (response.status == 200) {
        setLead(editedLead);
      }

      setEditProject(false);
    } catch (error) {
      console.log(`Unable to edit lead: ${error}`);
    }
  };

  const ProjectInformationField = () => {
    return (
      <FlexBetween>
        <Typography sx={{ mr: "5px" }}>Project Interested In:</Typography>
        {editProject && allProjects ? (
          <TextField
            select
            label="Select Project"
            sx={{ mb: "0.5rem", mr: "0.5rem", width: "15rem" }}
            value={selectedProject.name}
            variant="standard"
          >
            {allProjects
              .slice() // Create a shallow copy of the array to avoid modifying the original array
              .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
              .map(({ _id, name }) => (
                <MenuItem
                  key={_id}
                  value={name}
                  onClick={() => handleProjectChange({ _id, name })}
                >
                  {name}
                </MenuItem>
              ))}
          </TextField>
        ) : (
          <Typography
            fontWeight="bold"
            onDoubleClick={handleProjectDoubleClick}
          >
            {project.name}
          </Typography>
        )}
      </FlexBetween>
    );
  };

  return (
    <Box
      gridColumn="span 12"
      gridRow="span 8"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      color={theme.palette.secondary[300]}
      borderRadius="0.55rem"
    >
      <Typography variant="h5" color={theme.palette.secondary[300]}>
        Lead Information
      </Typography>
      <LeadInformationField
        field="name"
        displayName="Name"
        lead={lead}
        isEditing={isEditing}
        editedText={editedText}
        setEditedText={setEditedText}
        handleSubmitEdit={handleSubmitEdit}
        handleEditDoubleClick={handleEditDoubleClick}
      />
      <FlexBetween>
        <Typography sx={{ mr: "5px" }}>Phone Number 1:</Typography>
        <Typography
          fontWeight="bold"
          onDoubleClick={(event) => handleDoubleClick(lead.phoneNumberOne)}
        >
          {lead.phoneNumberOne}
        </Typography>
      </FlexBetween>
      <FlexBetween>
        <Typography sx={{ mr: "5px" }}>Phone Number 2:</Typography>
        <Typography
          fontWeight="bold"
          onDoubleClick={(event) => handleDoubleClick(lead.phoneNumberTwo)}
        >
          {lead.phoneNumberTwo}
        </Typography>
      </FlexBetween>
      <FlexBetween>
        <Typography sx={{ mr: "5px" }}>Email:</Typography>
        <Typography fontWeight="bold">{lead.email}</Typography>
      </FlexBetween>
      <LeadInformationField
        field="jobTitle"
        displayName="Job Title"
        lead={lead}
        isEditing={isEditing}
        editedText={editedText}
        setEditedText={setEditedText}
        handleSubmitEdit={handleSubmitEdit}
        handleEditDoubleClick={handleEditDoubleClick}
      />
      {project && <ProjectInformationField />}
    </Box>
  );
}

export default LeadInformationCard;
