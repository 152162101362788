// eslint-disable-next-line
import React, { useEffect, useRef, useState } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line
import {
  AppBar,
  Button,
  IconButton,
  InputBase,
  Toolbar,
  useTheme,
  Box,
  Typography,
  MenuItem,
  Menu,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { useLogout } from "state/hooks/useLogout";
import { useNavigate } from "react-router-dom";
import {
  useGetAllClientsQuery,
  useGetClientLeadsQuery,
  useGetTeamMemberByEmailQuery,
  useGetTeamMemberLeadsQuery,
  useGetNameSearchLeadsQuery,
} from "state/api";
// import { skipToken } from "@reduxjs/toolkit/query";

function Navbar({ isSidebarOpen, setIsSidebarOpen, isNonMobile }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { logout } = useLogout();

  const user = useSelector((state) => state.auth.user);
  const [leads, setLeads] = useState([{ name: "Type to search leads..." }]);
  const [nameSearchTyped, setNameSearchTyped] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const skip = nameSearch ? false : true;
  const [loading, setLoading] = useState(false);
  const textFieldRef = useRef(null);

  const handleSearch = () => {
    setNameSearch(nameSearchTyped);
    if (textFieldRef.current) {
      textFieldRef.current.click();
    }
  };

  const { data: allLeads } = useGetNameSearchLeadsQuery(nameSearch);
  console.log("All Leads: ", allLeads);

  // Handle the search bar if the user is a client
  const { data: allClients } = useGetAllClientsQuery();
  const [client, setClient] = useState();

  useEffect(() => {
    const client = allClients?.filter((c) => c.email == user?.email);
    client ? setClient(client[0]) : setClient();
  }, [user, allClients]);

  const { data: clientLeadData } = useGetClientLeadsQuery({
    id: client ? client._id : "",
    page: 0,
    pageSize: 10000,
    sort: JSON.stringify({ field: "createdAt", sort: "desc" }),
  });

  // Handle the search bar if the user is a team member
  const { data: teamMember } = useGetTeamMemberByEmailQuery(user?.email);
  const { data: teamMemberLeadData } = useGetTeamMemberLeadsQuery({
    id: teamMember ? teamMember.teamMember?._id : "",
    page: 0,
    pageSize: 10000,
    sort: JSON.stringify({ field: "createdAt", sort: "desc" }),
  });

  useEffect(() => {
    // user.role === "Admin"
    // ? setLeads(allLeads?.leads || [])
    // : setLeads(clientLeadData?.leads || [])

    if (user.role === "Admin") {
      console.log("In Admin");
      console.log("All Leads: ", allLeads);
      setLeads(allLeads?.leads || []);
    } else if (user.role === "Client") {
      setLeads(clientLeadData?.leads || []);
    } else if (user.role === "Team Member") {
      setLeads(teamMemberLeadData?.teamMemberLeads || []);
    }
  }, [allLeads, client, clientLeadData, teamMemberLeadData]);

  // Determine setMode Icon
  const current = theme.palette.mode;
  let modeIcon;
  if (current === "dark") {
    modeIcon = <DarkModeOutlined sx={{ fontSize: "25px" }} />;
  } else {
    modeIcon = <LightModeOutlined sx={{ fontSize: "25px" }} />;
  }

  // Logout button anchor element
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    logout();
  };

  // useNavigate
  const navigate = useNavigate();

  const handleLeadSelection = (lead) => {
    navigate(`leads/${lead._id}`);
  };

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween
          backgroundColor={theme.palette.background.alt}
          borderRadius="9px"
          gap="1rem"
          p="0.1rem 1.5rem"
        >
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon sx={{ color: theme.palette.secondary[200] }} />
          </IconButton>
          {isNonMobile && (
            <FlexBetween>
              <Autocomplete
                freeSolo
                sx={{
                  width: 300,
                  color: theme.palette.secondary[200],
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                  "& .MuiInputBase-root": {
                    padding: 0.5,
                    color: theme.palette.secondary[200],
                  },
                  "& .MuiFormLabel-root": {
                    position: "absolute",
                    top: -4,
                    color: theme.palette.secondary[200],
                  },
                }}
                options={leads || []} // Ensure leads is an array, or provide an empty array as a default
                getOptionLabel={(leads) => `${leads.name}`}
                // filterOptions={(x) => x}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                noOptionsText={loading ? "Loading..." : "No leads found."}
                onInputChange={(e, value) => setNameSearchTyped(value)}
                renderOption={(props, leads) => (
                  <Box
                    component="li"
                    {...props}
                    key={leads._id}
                    onClick={() => handleLeadSelection(leads)}
                  >
                    {leads.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search leads..."
                    inputRef={textFieldRef}
                    sx={{ color: theme.palette.secondary[200] }}
                    // onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                  />
                )}
              />
              <Button onClick={handleSearch}>
                <Search />
              </Button>
            </FlexBetween>
          )}
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          {/* <IconButton onClick={() => dispatch(setMode())}>
            {modeIcon}
          </IconButton> */}
          <IconButton onClick={() => navigate("/settings")}>
            <SettingsOutlined />
          </IconButton>

          {/* USER INFORMATION */}
          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: theme.palette.primary[500] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontSize="0.75rem"
                  sx={{ color: theme.palette.primary[500] }}
                >
                  {user.occupation}
                </Typography>
              </Box>
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.primary[500], fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
