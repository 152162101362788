import React from "react";
import { Typography, useTheme } from "@mui/material";

function SectionHeader({ title }) {
  const theme = useTheme();
  return (
    <Typography
      variant="h5"
      color={theme.palette.primary[500]}
      fontWeight="bold"
      sx={{ mb: "5px" }}
    >
      {title}
    </Typography>
  );
}

export default SectionHeader;
