import React, { useState, useEffect } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import {
  useGetAllProjectsQuery,
  useGetClientLeadsQuery,
  useGetSingleClientQuery,
} from "state/api";
import { useParams, useNavigate } from "react-router-dom";
import Header from "components/Header";
import ClientStatBox from "components/ClientStatBox";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SourceMap } from "constants/ValueMaps";

function SingleClient() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useGetSingleClientQuery(id);
  console.log("🚀 ~ file: SingleClient.js:21 ~ SingleClient ~ data:", data);
  const { data: allProjects } = useGetAllProjectsQuery();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });
  const [search, setSearch] = useState("");

  dayjs.extend(utc);
  const defaultStartDate = dayjs().subtract(1, "day").utc(true).toISOString();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  const [searchInput, setSearchInput] = useState("");
  const [campaignPrefix, setCampaignPrefix] = useState("");
  const [isPointer, setIsPointer] = useState(false);

  const { data: leadData } = useGetClientLeadsQuery({
    id,
    page,
    pageSize,
    sort: JSON.stringify(sort ? sort : { field: "createdAt", sort: "desc" }),
    search,
    startDate,
    endDate,
    campaignPrefix: campaignPrefix.toLowerCase(),
  });

  const [leads, setLeads] = useState(null);
  const [total, setTotal] = useState(null);

  const [clientProjects, setClientProjects] = useState(null);
  const [selectedProject, setSelectedProject] = useState({
    _id: "",
    name: "",
    campaignPrefix: "",
  });

  useEffect(() => {
    setLeads(leadData?.leads);
    setTotal(leadData?.total);
  }, [leadData]);

  useEffect(() => {
    let clientProjs = [];
    if (data && allProjects) {
      for (const clientProject of data?.projects) {
        for (const project of allProjects) {
          if (clientProject.project === project._id) {
            clientProjs.push({
              _id: clientProject.project,
              name: project.name,
              campaignPrefix: project.campaignPrefix,
            });
          }
        }
      }
    }

    setClientProjects(clientProjs);
  }, [allProjects, data]);

  useEffect(() => {
    setCampaignPrefix(selectedProject.campaignPrefix);
  }, [selectedProject]);

  const handleDeleteProject = async () => {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}clientsServer/clients/delete/${id}`,
      configToken
    );
    const userResponse = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}usersServer/deleteByEmail/${data.email}`,
      configToken
    );
    navigate("/clients");
    window.location.reload();
  };

  const handleFeedbackUpdate = async (updatedRow) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editFeedback/${updatedRow._id}`,
        {
          feedback: updatedRow.feedback,
          writtenFeedback: updatedRow.writtenFeedback,
        },
        configToken
      );
      return response.data.lead;
    } catch (error) {
      console.log("🚀 ~ handleFeedbackUpdate ~ error:", error);
    }
  };

  const handleRowDoubleClick = async (params) => {
    console.log(params);
    if (params.field === "name") {
      const rowId = params.row._id;
      navigate(`/leads/${rowId}`);
    }
  };

  const handleCellOver = (event) => {
    const field = event.currentTarget.dataset.field;
    if (field === "name") {
      setIsPointer(true);
    }
  };

  const handleCellOut = (event) => {
    const field = event.currentTarget.dataset.field;
    if (field === "name") {
      setIsPointer(false);
    }
  };

  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 0.2,
    // },
    {
      field: "createdAt",
      headerName: "Date",
      // flex: 0.3,
      width: 90,
      valueFormatter: (params) => {
        const date = new Date(params.value);

        // Get the local time components
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are 0-based
        const year = date.getFullYear();

        // Create the formatted date string
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
      },
    },
    {
      field: "source",
      headerName: "Source",
      // flex: 0.25,
      width: 30,
      valueFormatter: (params) => {
        return SourceMap[params.value];
      },
    },
    {
      field: "campaignName",
      headerName: "Campaign Name",
      // flex: 0.4,
      width: 150,
    },
    {
      field: "name",
      headerName: "Full Name",
      // flex: 0.5,
      width: 150,
      renderCell: (props) => (
        <div
          style={{
            cursor: isPointer ? "pointer" : "default",
          }}
        >
          {props.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      // flex: 0.4,
      width: 150,
    },
    {
      field: "phoneNumberOne",
      headerName: "Phone Number 1",
      // flex: 0.5,
      width: 150,
    },
    {
      field: "phoneNumberTwo",
      headerName: "Phone Number 2",
      // flex: 0.5,
      width: 150,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      // flex: 0.3,
      width: 150,
    },
    {
      field: "leadCreationComments",
      headerName: "Automatically Assigned?",
      width: 200,
    },
    {
      field: "otherQuestions",
      headerName: "Other Questions",
      width: 200,
      renderCell: (params) => (
        <Box>
          {params.value.map((questionObj, index) => (
            <div key={index}>
              {`${questionObj.question} : ${questionObj.answer}`}
            </div>
          ))}
        </Box>
      ),
    },
    {
      field: "feedback",
      headerName: "Feedback",
      // flex: 0.4,
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "No Answer",
        "Interested",
        "Not Interested",
        "Switched Off",
        "Call Back",
        "Duplicate",
        "Follow Up",
        "Wrong Number",
        "Low Budget",
        "",
      ],
    },
    {
      field: "writtenFeedback",
      headerName: "Notes",
      // flex: 0.4,
      width: 150,
      editable: true,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title={`${data?.name} - ${
            data?.recievingLeads
              ? "Is Recieving Leads"
              : "Is NOT Recieving Leads"
          }`}
          subtitle={`Specific information and data for ${data?.name}`}
        />
        <Box>
          <Button
            onClick={() => navigate(`/clients/update/${id}`)}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Edit Client
          </Button>
          <Button
            onClick={() => handleDeleteProject()}
            sx={{
              backgroundColor: "#9e1b32",
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              ml: "10px",
            }}
          >
            Delete Client
          </Button>
        </Box>
      </FlexBetween>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(6, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {data || !isLoading ? (
          data.projects.map((project) => (
            <ClientStatBox
              key={project._id}
              _id={project.project}
              numberLeadsRequested={project.numberLeads}
              numberLeadsReceived={project.numberLeadsReceived}
            />
          ))
        ) : (
          <>Loading...</>
        )}
      </Box>
      <Box mt="40px">
        <FlexBetween>
          <Box>
            {clientProjects ? (
              <TextField
                select
                label="Select Project"
                sx={{ mb: "0.5rem", mr: "0.5rem", width: "15rem" }}
                value={selectedProject.name}
                variant="standard"
              >
                <MenuItem
                  value="All"
                  onClick={() =>
                    setSelectedProject({
                      _id: "",
                      name: "",
                      campaignPrefix: "",
                    })
                  }
                >
                  All
                </MenuItem>
                {clientProjects.map(({ _id, name, campaignPrefix }) => (
                  <MenuItem
                    key={_id}
                    value={name}
                    onClick={() =>
                      setSelectedProject({ _id, name, campaignPrefix })
                    }
                  >
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <>Loading...</>
            )}
          </Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display="flex" justifyContent="end">
              <DatePicker
                label="Start Date"
                value={dayjs.utc(dayjs(startDate))}
                onChange={(newValue) => {
                  const utcDate = dayjs(newValue).utc(true);
                  setStartDate(utcDate?.toISOString() || null);
                }}
                textField={(params) => (
                  <TextField
                    {...params}
                    sx={{ mb: "0.5rem", width: "15rem" }}
                    variant="standard"
                  />
                )}
              />
              <DatePicker
                label="End Date"
                value={dayjs.utc(dayjs(endDate))}
                sx={{ ml: "10px" }}
                onChange={(newValue) => {
                  const utcDate = dayjs(newValue).utc(true);
                  setEndDate(utcDate?.toISOString() || null);
                }}
                textField={(params) => (
                  <TextField
                    {...params}
                    sx={{ mb: "0.5rem", width: "15rem" }}
                    variant="standard"
                  />
                )}
              />
            </Box>
          </LocalizationProvider>
        </FlexBetween>
      </Box>
      <Box
        mt="40px"
        height="75vh"
        width="93vw"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders .MuiSvgIcon-root": {
            color: theme.palette.secondary[100],
          },
          // "& .MuiDataGrid-virtualScroller": {
          //   backgroundColor: theme.palette.primary.light
          // },
          "& .MuiDataGrid-footerContainer": {
            // backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.primary[500]} !important`,
          },
        }}
      >
        <DataGrid
          loading={!leads}
          getRowId={(row) => row._id}
          rows={leads || []}
          columns={columns}
          rowCount={total || 0}
          pageSizeOptions={[20, 50, 100]}
          pagination
          paginationModel={{ page: page, pageSize: pageSize }}
          paginationMode="server"
          sortingMode="server"
          disableRowSelectionOnClick
          checkboxSelection
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={(newSelection) =>
            setSelectedRows(newSelection)
          }
          processRowUpdate={(updatedRow, originalRow) =>
            handleFeedbackUpdate(updatedRow)
          }
          onPaginationModelChange={(newModel) => {
            setPage(newModel.page);
            setPageSize(newModel.pageSize);
          }}
          onCellDoubleClick={handleRowDoubleClick}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          slots={{ toolbar: DataGridCustomToolbar }}
          slotProps={{
            toolbar: {
              searchInput,
              setSearchInput,
              setSearch,
              columns,
              filename: data?.name,
              selectedRows,
            },
            cell: {
              onMouseEnter: handleCellOver,
              onMouseLeave: handleCellOut,
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default SingleClient;
