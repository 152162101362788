import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetAllClientTeamMembersQuery,
  useGetAllClientsQuery,
  useGetTeamMemberLeadsQuery,
} from "state/api";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";

const LeadInformation = ({ _id, name, jobTitle, feedback }) => {
  const theme = useTheme();
  return (
    <Box sx={{ mb: "10px" }}>
      <FlexBetween>
        <Typography
          sx={{ fontSize: 14 }}
          color={theme.palette.secondary[700]}
          gutterBottom
        >
          {name}
        </Typography>
        <Typography>{jobTitle}</Typography>
        <Typography>{feedback}</Typography>
      </FlexBetween>
    </Box>
  );
};

const TeamMember = ({ _id, name, email, phoneNumber }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const { data } = useGetTeamMemberLeadsQuery({ id: _id });

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent sx={{ color: theme.palette.secondary[200] }}>
        <FlexBetween>
          <Box>
            {/* <Typography
              sx={{ fontSize: 14 }}
              color={theme.palette.secondary[700]}
              gutterBottom
            >
              {country}
            </Typography> */}
            <Typography
              variant="h5"
              component="div"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/teammembers/${_id}`);
              }}
            >
              {name}
            </Typography>
          </Box>
          <Box>
            <FlexBetween>
              <Typography sx={{ mr: "5px" }}>Leads Received Today:</Typography>
              <Typography fontWeight="bold">{data?.totalToday}</Typography>
            </FlexBetween>
            <FlexBetween>
              <Typography sx={{ mr: "5px" }}>
                Leads Received This Week:
              </Typography>
              <Typography fontWeight="bold">{data?.totalThisWeek}</Typography>
            </FlexBetween>
          </Box>
        </FlexBetween>
      </CardContent>
      <CardActions sx={{ color: theme.palette.secondary[200] }}>
        <Button
          variant="primary"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "See Less" : "See More"}
        </Button>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Typography
            sx={{ fontSize: 14, fontWeight: "bold" }}
            color={theme.palette.secondary[700]}
            gutterBottom
          >
            Last Five Leads
          </Typography>
          {data?.teamMemberLeads.slice(0, 5).map((lead) => (
            <LeadInformation
              key={lead._id}
              _id={lead._id}
              name={lead.name}
              jobTitle={lead.jobTitle}
              feedback={lead.feedback}
            />
          ))}
          {/* <Typography variant="body2">{description}</Typography> */}
        </CardContent>
      </Collapse>
    </Card>
  );
};

function ClientTeamMembers() {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const { data: allClients } = useGetAllClientsQuery();
  const [client, setClient] = useState();

  useEffect(() => {
    const client = allClients?.filter((c) => c.email == user.email);
    client ? setClient(client[0]) : setClient();
  }, [user, allClients]);

  const [searchTeamMembers, setSearchTeamMembers] = useState("");

  const { data } = useGetAllClientTeamMembersQuery(client?._id);

  const filteredTeamMembers = data
    ? data.teamMembers.filter((teamMember) =>
        teamMember.name
          .toLowerCase()
          .startsWith(searchTeamMembers.toLowerCase())
      )
    : [];

  // Make it mobile responsive
  const flexDirection = isNonMobile ? "row" : "column";
  const alignItems = isNonMobile ? "center" : "";

  return (
    <Box m="1.5rem 2.5rem">
      <Box
        display="flex"
        flexDirection={flexDirection}
        justifyContent="space-between"
        alignItems={alignItems}
      >
        <Header title="Team Members" subtitle="See all your team members." />
        <Box>
          <TextField
            sx={{ mb: "0.5rem", width: "15rem", mr: "3rem" }}
            label="Search Team Members..."
            variant="standard"
            value={searchTeamMembers}
            onChange={(e) => setSearchTeamMembers(e.target.value)}
          />
          <Button
            onClick={() => navigate("/teammembers/create")}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Add New Team Member
          </Button>
        </Box>
      </Box>
      {data ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {filteredTeamMembers
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
            .map(({ _id, name, email, phoneNumber }) => (
              <TeamMember
                key={_id}
                _id={_id}
                name={name}
                email={email}
                phoneNumber={phoneNumber}
              />
            ))}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
}

export default ClientTeamMembers;
