import React, { useState, useEffect } from "react";
import {
  Card,
  useTheme,
  Box,
  TextField,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLogin } from "state/hooks/useLogin";
import { useNavigate } from "react-router-dom";
import phiegLogo from "../phieg-logo-inverted.png";

function Login() {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  // const [shouldCheckError, setShouldCheckError] = useState(false)
  const { login, error, isLoading, checkError } = useLogin();

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (!error && checkError) {
      navigate("/dashboard");
    }
  }, [checkError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(email, password);
  };

  const formWidth = isNonMediumScreens ? "30%" : "80%";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "70vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={phiegLogo} width="100px" />
      <Card
        sx={{
          backgroundImage: "none",
          backgroundColor: theme.palette.primary[50],
          borderRadius: "0.55rem",
          p: "2rem",
          height: "300px",
          width: formWidth,
          mt: "200px",
        }}
      >
        <Typography
          variant="h4"
          color={theme.palette.secondary[100]}
          fontWeight="bold"
          sx={{ mb: "20px" }}
        >
          Phieg | Lead Management System
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <TextField
            label="Email"
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            variant="outlined"
            sx={{
              // Root class for the input field
              "& .MuiOutlinedInput-root": {
                color: "#000",
                fontFamily: "Arial",
                fontWeight: "bold",
                // Class for the border around the input field
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2e2e2e",
                  borderWidth: "2px",
                },
              },
              // Class for the label of the input field
              "& .MuiInputLabel-outlined": {
                color: "#2e2e2e",
                fontWeight: "bold",
              },
            }}
          />
          <TextField
            label="Password"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
            variant="outlined"
            // color="secondary"
            sx={{
              // Root class for the input field
              "& .MuiOutlinedInput-root": {
                color: "#000",
                fontFamily: "Arial",
                fontWeight: "bold",
                // Class for the border around the input field
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2e2e2e",
                  borderWidth: "2px",
                },
              },
              // Class for the label of the input field
              "& .MuiInputLabel-outlined": {
                color: "#2e2e2e",
                fontWeight: "bold",
              },
            }}
          />
          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            color="secondary"
            sx={{ mt: "10px" }}
          >
            Login
          </Button>
        </form>
      </Card>
      {error && (
        <Typography sx={{ mt: "30px", color: "red" }}>
          {error.message}
        </Typography>
      )}
    </Box>
  );
}

export default Login;
