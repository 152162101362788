import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setCredentials } from "state/authSlice";

export const useLogin = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [checkError, setCheckError] = useState(false)

  const dispatch = useDispatch()
  
  const login = async (email, password) => {
    setIsLoading(true)
    setError(null)

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}usersServer/login`, {email, password})
      const { user, token } = await response.data
      
      // save user to local storage
      localStorage.setItem("user", JSON.stringify({user, token}))

      // update the authcontext
      dispatch(setCredentials({ user, token }))

      setIsLoading(false)
      setCheckError(true)
    } catch (error) {
      setIsLoading(false)
      setError(error.response.data)
      setCheckError(true)
    }
  }

  return { login, isLoading, error, checkError }
}