import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";

function StatBox({ title, value, icon, egypt, uae, unassigned }) {
  const theme = useTheme();
  return (
    <Box
      gridColumn="span 3"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      borderRadius="0.55rem"
    >
      <FlexBetween>
        <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
          {title}
        </Typography>
        {icon}
      </FlexBetween>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          mt: "10px",
          color: theme.palette.secondary[200],
        }}
      >
        <Typography
          variant="h3"
          fontWeight="600"
          sx={{ color: theme.palette.secondary[200] }}
        >
          {value}
        </Typography>
        {egypt && (
          <FlexBetween gap="1rem">
            <Typography
              variant="h5"
              fontStyle="italic"
              sx={{ color: theme.palette.secondary.light }}
            >
              {egypt}
            </Typography>
            <Typography>in Egypt</Typography>
          </FlexBetween>
        )}
        {(uae || uae == 0) && (
          <FlexBetween gap="1rem">
            <Typography
              variant="h5"
              fontStyle="italic"
              sx={{ color: theme.palette.secondary.light }}
            >
              {uae}
            </Typography>
            <Typography>in the UAE</Typography>
          </FlexBetween>
        )}
        {unassigned && (
          <FlexBetween gap="1rem">
            <Typography
              variant="h5"
              fontStyle="italic"
              sx={{ color: theme.palette.secondary.light }}
            >
              {unassigned}
            </Typography>
            <Typography>are Unassigned</Typography>
          </FlexBetween>
        )}
      </Box>
    </Box>
  );
}

export default StatBox;
