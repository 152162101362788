import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";

function CampaignInformationCard({ lead }) {
  const theme = useTheme();

  return (
    <Box
      gridColumn="span 6"
      gridRow="span 4"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      color={theme.palette.secondary[300]}
      borderRadius="0.55rem"
    >
      <Typography variant="h5" color={theme.palette.secondary[300]}>
        Campaign Information
      </Typography>
      <FlexBetween>
        <Typography sx={{ mr: "5px" }}>Source:</Typography>
        <Typography fontWeight="bold">{lead.source}</Typography>
      </FlexBetween>
      <FlexBetween>
        <Typography sx={{ mr: "5px" }}>Campaign Name: </Typography>
        <Typography fontWeight="bold">{lead.campaignName}</Typography>
      </FlexBetween>
      <FlexBetween>
        <Typography sx={{ mr: "5px" }}>Ad Name:</Typography>
        <Typography fontWeight="bold">{lead.adName}</Typography>
      </FlexBetween>
    </Box>
  );
}

export default CampaignInformationCard;
