import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSingleLeadHistoryQuery, useGetSingleLeadQuery } from "state/api";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Header from "components/Header";
import LeadInformationCard from "./singleLeadsCards/LeadInformationCard";
import CampaignInformationCard from "./singleLeadsCards/CampaignInformationCard";
import ClientProjectInformationCard from "./singleLeadsCards/ClientProjectInformationCard";
import OtherQuestionsCard from "./singleLeadsCards/OtherQuestionsCard";
import { useDispatch, useSelector } from "react-redux";
import LeadHistory from "./singleLeadsCards/LeadHistory";
import FlexBetween from "components/FlexBetween";
import EditStatusFeedback from "./singleLeadsCards/EditStatusFeedback";
import useStoreExistingFilters from "hooks/useStoreExistingFilters";
import { setMode } from "state";

function SingleLead() {
  const { id } = useParams();
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);
  const { data: hist } = useGetSingleLeadHistoryQuery(id);
  const leadHistory = hist ? hist.leadHistory : [];

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const { data } = useGetSingleLeadQuery(id);
  const [lead, setLead] = useState();

  useEffect(() => {
    setLead(data?.lead);
  }, [data]);

  const mode = useSelector((state) => state.global.mode);
  const dispatch = useDispatch();
  const filters = {
    mode,
  };
  const { storeExistingFilters, getAndRemoveFilters } =
    useStoreExistingFilters();

  useEffect(() => {
    // Get and remove filters from localStorage
    const filters = getAndRemoveFilters();

    if (filters) {
      // Apply mode filter from localStorage
      dispatch(setMode(filters.mode));
    }
  }, []);

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={`${lead?.name}`}
        subtitle={`Detailed Information on ${lead?.name}`}
      />
      <Box display="flex">
        <Box
          mt="20px"
          flex={`0 0 ${leadHistory ? "80%" : "100%"}`}
          mr="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="15px"
          gap="20px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {lead ? (
            <LeadInformationCard lead={lead} setLead={setLead} />
          ) : (
            <>Loading...</>
          )}
          {user.role == "Admin" &&
            (lead ? <CampaignInformationCard lead={lead} /> : <>Loading...</>)}
          {user.role == "Admin" &&
            (lead ? (
              <ClientProjectInformationCard lead={lead} />
            ) : (
              <>Loading...</>
            ))}
          {lead && lead.otherQuestions.length > 0 && (
            <OtherQuestionsCard lead={lead} />
          )}
          {lead ? <EditStatusFeedback lead={lead} /> : <>Loading...</>}
        </Box>
        {leadHistory && (
          <LeadHistory
            leadHistory={leadHistory}
            storeExistingFilters={storeExistingFilters(filters)}
          />
        )}
      </Box>
    </Box>
  );
}

export default SingleLead;
