import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { useGetAllProjectsQuery } from "state/api";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { useNavigate, useLocation } from "react-router-dom";

const Project = ({ _id, name, description, campaignPrefix, country }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent sx={{ color: theme.palette.secondary[300] }}>
        <Typography
          sx={{ fontSize: 14 }}
          color={theme.palette.secondary[700]}
          gutterBottom
        >
          {country} - {campaignPrefix}
        </Typography>
        <Typography
          variant="h5"
          component="div"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/projects/${_id}`);
          }}
        >
          {name}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="primary"
          size="small"
          sx={{ color: theme.palette.secondary[300] }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          See More
        </Button>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Typography variant="body2">{description}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

function Projects() {
  const theme = useTheme();
  const { data, isLoading } = useGetAllProjectsQuery();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate();
  const [searchCampaignPrefix, setSearchCampaignPrefix] = useState("");

  // Filter projects based on the search query
  const filteredProjects = data
    ? data.filter((project) =>
        project.campaignPrefix
          .toLowerCase()
          .startsWith(searchCampaignPrefix.toLowerCase())
      )
    : [];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="PROJECTS" subtitle="See all available projects." />
        <Box>
          <TextField
            sx={{ mb: "0.5rem", width: "15rem", mr: "3rem" }}
            label="Search campaign prefix..."
            variant="standard"
            value={searchCampaignPrefix}
            onChange={(e) => setSearchCampaignPrefix(e.target.value)}
          />
          <Button
            onClick={() => navigate("/projects/create")}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Add New Project
          </Button>
        </Box>
      </FlexBetween>
      {data || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(3, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {filteredProjects
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
            .map(({ _id, name, description, campaignPrefix, country }) => (
              <Project
                key={_id}
                _id={_id}
                name={name}
                description={description}
                campaignPrefix={campaignPrefix}
                country={country}
              />
            ))}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
}

export default Projects;
