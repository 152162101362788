import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function FeedbackBox({ feedbackType, count, totalLeads }) {
  console.log("🚀 ~ FeedbackBox ~ count:", count);
  const theme = useTheme();
  const completionPercentage = count / totalLeads;

  return (
    <Box
      gridColumn="span 4"
      gridRow="span 2"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      borderRadius="0.55rem"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        height="100%"
        alignItems="center"
      >
        <Typography
          variant="h4" // Increase variant to h4 for bigger text
          align="center"
          fontWeight="bold" // Add fontWeight to make it bold
          color={theme.palette.secondary[200]}
          // mb="20px" // Add margin-bottom for spacing
        >
          {feedbackType}:
        </Typography>
        <Box display="flex" width="20%" height="100%">
          <CircularProgressbar
            value={completionPercentage}
            maxValue={1}
            text={`${(completionPercentage * 100).toFixed(1)}%`}
            styles={{
              path: {
                stroke: theme.palette.secondary[200],
              },
              text: {
                fill: theme.palette.secondary[200],
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default FeedbackBox;
