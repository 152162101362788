import { useTheme, Box, TextField, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import {
  useGetAllProjectsQuery,
  useGetSingleProjectQuery,
  useGetTeamMemberLeadsQuery,
  useGetTeamMemberQuery,
} from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { useSelector } from "react-redux";

function SingleTeamMember() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  let { data } = useGetTeamMemberQuery(id);
  data = data ? data.teamMember : undefined;

  const { data: projects } = useGetAllProjectsQuery();

  const [leads, setLeads] = useState();
  const [total, setTotal] = useState();

  const [teamMemberProjects, setTeamMemberProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({
    _id: "",
    name: "",
    campaignPrefix: "",
  });

  dayjs.extend(utc);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isPointer, setIsPointer] = useState(false);
  const [campaignPrefix, setCampaignPrefix] = useState("");
  const [feedback, setFeedback] = useState("");
  const feedbackTypes = [
    "No Answer",
    "Interested",
    "Not Interested",
    "Switched Off",
    "Call Back",
    "Duplicate",
    "Follow Up",
    "Wrong Number",
    "Low Budget",
  ];

  const { data: teamMemberLeads } = useGetTeamMemberLeadsQuery({
    id: id,
    page,
    pageSize,
    sort: JSON.stringify(sort ? sort : { field: "createdAt", sort: "desc" }),
    search,
    startDate,
    endDate,
    campaignPrefix: campaignPrefix.toLowerCase(),
    feedback,
  });

  useEffect(() => {
    setCampaignPrefix(selectedProject.campaignPrefix);
  }, [selectedProject]);

  useEffect(() => {
    if (teamMemberLeads) {
      setLeads(teamMemberLeads.teamMemberLeads);
      setTotal(teamMemberLeads.teamMemberLeadsTotal);
    }
  }, [teamMemberLeads]);

  useEffect(() => {
    if (leads && projects) {
      const allLeads = teamMemberLeads.teamMemberLeads;
      const uniqueProjects = [
        ...new Set(allLeads.map((lead) => lead.projectId)),
      ];

      if (uniqueProjects) {
        let teamMemberProjects = [];
        uniqueProjects.forEach((projectId) => {
          const project = projects.filter((proj) => proj._id === projectId)[0];
          if (project) {
            teamMemberProjects.push({
              _id: project._id,
              name: project.name,
              campaignPrefix: project.campaignPrefix,
            });
          }
        });

        setTeamMemberProjects(teamMemberProjects);
      }
    }
  }, [leads, projects]);

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleFeedbackUpdate = async (updatedRow) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editFeedback/${updatedRow._id}`,
        {
          feedback: updatedRow.feedback,
          writtenFeedback: updatedRow.writtenFeedback,
        },
        configToken
      );
      return response.data.lead;
    } catch (error) {
      console.log("🚀 ~ handleFeedbackUpdate ~ error:", error);
    }
  };

  const handleCellOver = (event) => {
    const field = event.currentTarget.dataset.field;
    if (field === "name") {
      setIsPointer(true);
    }
  };

  const handleCellOut = (event) => {
    const field = event.currentTarget.dataset.field;
    if (field === "name") {
      setIsPointer(false);
    }
  };

  const handleRowDoubleClick = async (params) => {
    if (params.field === "name") {
      const rowId = params.row._id;
      navigate(`/leads/${rowId}`);
    }
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Date",
      // flex: 0.3,
      width: 90,
      valueFormatter: (params) => {
        const date = new Date(params.value);

        // Get the local time components
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are 0-based
        const year = date.getFullYear();

        // Create the formatted date string
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
      },
    },
    {
      field: "campaignName",
      headerName: "Campaign Name",
      // flex: 0.4,
      width: 150,
    },
    {
      field: "name",
      headerName: "Full Name",
      // flex: 0.5,
      width: 150,
      renderCell: (props) => (
        <div
          style={{
            cursor: isPointer ? "pointer" : "default",
          }}
        >
          {props.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      // flex: 0.4,
      width: 150,
    },
    {
      field: "phoneNumberOne",
      headerName: "Phone Number 1",
      // flex: 0.5,
      width: 150,
    },
    {
      field: "phoneNumberTwo",
      headerName: "Phone Number 2",
      // flex: 0.5,
      width: 150,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      // flex: 0.3,
      width: 150,
    },
    {
      field: "otherQuestions",
      headerName: "Other Questions",
      width: 200,
      renderCell: (params) => (
        <Box>
          {params.value.map((questionObj, index) => (
            <div key={index}>
              {`${questionObj.question} : ${questionObj.answer}`}
            </div>
          ))}
        </Box>
      ),
    },
    {
      field: "feedback",
      headerName: "Feedback",
      // flex: 0.4,
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "No Answer",
        "Interested",
        "Not Interested",
        "Switched Off",
        "Call Back",
        "Duplicate",
        "Follow Up",
        "Wrong Number",
        "Low Budget",
        "",
      ],
    },
    {
      field: "writtenFeedback",
      headerName: "Notes",
      // flex: 0.4,
      width: 150,
      editable: true,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title={data?.name}
          subtitle={`Specific information and data for ${data?.name}`}
        />
      </FlexBetween>
      <Box mt="40px">
        <FlexBetween>
          <Box>
            {teamMemberProjects ? (
              <TextField
                select
                label="Select Project"
                sx={{ mb: "0.5rem", mr: "0.5rem", width: "15rem" }}
                value={selectedProject.name}
                variant="standard"
              >
                <MenuItem
                  value="All"
                  onClick={() =>
                    setSelectedProject({
                      _id: "",
                      name: "",
                      campaignPrefix: "",
                    })
                  }
                >
                  All
                </MenuItem>
                {teamMemberProjects.map(({ _id, name, campaignPrefix }) => (
                  <MenuItem
                    key={_id}
                    value={name}
                    onClick={() =>
                      setSelectedProject({ _id, name, campaignPrefix })
                    }
                  >
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <>Loading...</>
            )}
            <TextField
              select
              label="Select Feedback"
              sx={{ mb: "0.5rem", mr: "0.5rem", width: "15rem" }}
              value={feedback}
              variant="standard"
            >
              <MenuItem value="All" onClick={() => setFeedback("")}>
                All
              </MenuItem>
              {feedbackTypes.map((f) => (
                <MenuItem key={f} value={f} onClick={() => setFeedback(f)}>
                  {f}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display="flex" justifyContent="end">
              <DatePicker
                label="Start Date"
                value={dayjs.utc(dayjs(startDate))}
                onChange={(newValue) => {
                  const utcDate = dayjs(newValue).utc(true);
                  setStartDate(utcDate?.toISOString() || null);
                }}
                textField={(params) => (
                  <TextField
                    {...params}
                    sx={{ mb: "0.5rem", width: "15rem" }}
                    variant="standard"
                  />
                )}
              />
              <DatePicker
                label="End Date"
                value={dayjs.utc(dayjs(endDate))}
                sx={{ ml: "10px" }}
                onChange={(newValue) => {
                  const utcDate = dayjs(newValue).utc(true);
                  setEndDate(utcDate?.toISOString() || null);
                }}
                textField={(params) => (
                  <TextField
                    {...params}
                    sx={{ mb: "0.5rem", width: "15rem" }}
                    variant="standard"
                  />
                )}
              />
            </Box>
          </LocalizationProvider>
        </FlexBetween>
      </Box>
      <Box
        mt="40px"
        height="75vh"
        width="93vw"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders .MuiSvgIcon-root": {
            color: theme.palette.secondary[100],
          },
          // "& .MuiDataGrid-virtualScroller": {
          //   backgroundColor: theme.palette.primary.light
          // },
          "& .MuiDataGrid-footerContainer": {
            // backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.primary[500]} !important`,
          },
        }}
      >
        <DataGrid
          loading={!leads}
          getRowId={(row) => row._id}
          rows={leads || []}
          columns={columns}
          rowCount={total || 0}
          pageSizeOptions={[20, 50, 100]}
          pagination
          paginationModel={{ page: page, pageSize: pageSize }}
          paginationMode="server"
          sortingMode="server"
          disableRowSelectionOnClick
          processRowUpdate={(updatedRow, originalRow) =>
            handleFeedbackUpdate(updatedRow)
          }
          onPaginationModelChange={(newModel) => {
            setPage(newModel.page);
            setPageSize(newModel.pageSize);
          }}
          onCellDoubleClick={handleRowDoubleClick}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          slots={{ toolbar: DataGridCustomToolbar }}
          slotProps={{
            toolbar: {
              searchInput,
              setSearchInput,
              setSearch,
              columns,
              filename: data?.name,
            },
            cell: {
              onMouseEnter: handleCellOver,
              onMouseLeave: handleCellOut,
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default SingleTeamMember;
