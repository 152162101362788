import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const useUnassignLeads = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const unassignLeads = async (leads, fromWho) => {
    let unassignedCount = leads.length; // Initialize counter

    for (const leadId of leads) {
      try {
        setIsLoading(true);
        setError(null);

        if (fromWho == "client") {
          const response = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}leadsServer/leads/unassignLead/${leadId}`,
            {},
            configToken
          );
        } else if (fromWho == "teamMember") {
          const response = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}leadsServer/leads/unassignLeadFromTeamMember/${leadId}`,
            {},
            configToken
          );
          console.log("🚀 ~ unassignLeads ~ response:", response);
        }

        unassignedCount--;
        if (unassignedCount === 0) {
          setIsLoading(false);
        }
      } catch (err) {
        unassignedCount--;

        if (unassignedCount === 0) {
          setIsLoading(false);
        }

        setError(err);
      }
    }
  };

  return { unassignLeads, isLoading, error };
};

export default useUnassignLeads;
