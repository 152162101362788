export const SelectComponentBorder = (color) => {
  return {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: color, // Set the outline color to black
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: color, // Set the outline color to black on hover
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: color, // Set the outline color to black when focused
    },
    "& .MuiSelect-icon": {
      color: color, // Set the arrow color to black
    },
    "& .MuiSelect-select": {
      color: color, // Set the selected value color to black
    },
  };
};
