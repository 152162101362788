import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { TextField } from "@mui/material";

const CustomDatePicker = ({ label, state, setState }) => {
  dayjs.extend(utc);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={dayjs.utc(dayjs(state))}
        onChange={(newValue) => {
          const utcDate = dayjs(newValue).utc(true);
          setState(utcDate?.toISOString() || null);
        }}
        textField={(params) => (
          <TextField
            {...params}
            sx={{ mb: "0.5rem", width: "15rem" }}
            variant="standard"
          />
        )}
      />
    </LocalizationProvider>
  );
};
export default CustomDatePicker;
