import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  // TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";

import {
  useTheme,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import useStoreExistingFilters from "hooks/useStoreExistingFilters";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function LeadHistory({ leadHistory, storeExistingFilters }) {
  const { id } = useParams();
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);
  const configToken = useSelector((state) => state.auth.configToken);

  const [history, setHistory] = useState([]);
  const [newHist, setNewHist] = useState("");
  const [open, setOpen] = useState(false);

  const newHistOptions = ["I met with the lead.", "The deal is closed."];
  const handleNewHistDoc = async () => {
    const docToEvent = {
      "I met with the lead.": ["meeting", `${user?.name} met with lead.`],
      "The deal is closed.": ["closed", `Closed the deal!`],
    };

    if (newHist) {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}leadsServer/leadHistory/createDoc/${id}`,
        {
          type: docToEvent[newHist][0],
          info: docToEvent[newHist][1],
        },
        configToken
      );
      setOpen(false);
      storeExistingFilters();
      window.location.reload();
    }
  };

  const historyTypeToStatus = {
    call: "Lead Called",
    firstCall: "Attempted First Call",
    followed: "Followed Up",
    meeting: "Meeting",
    closed: "Closed Deal",
    creation: "Lead Created",
    adminAssignment: "Assigned to Client",
    adminUnassignment: "Unassigned From Client",
    clientAssignment: "Assigned to Team Member",
    clientUnassignment: "Unassigned From Team Member",
  };

  useEffect(() => {
    if (user && leadHistory) {
      const newHist = [];
      const client = user.role === "Client";
      const teamMember = user.role === "Team Member";
      leadHistory.forEach((hist) => {
        let push = false;
        if (
          ["adminAssignment", "adminUnassignment", "creation"].includes(
            hist.eventType
          )
        ) {
          if (!client && !teamMember) {
            push = true;
          }
        } else if (
          ["clientAssignment", "clientUnassignment"].includes(hist.eventType)
        ) {
          if (!teamMember) {
            push = true;
          }
        } else {
          push = true;
        }

        if (push) {
          newHist.push({
            ...hist,
            eventType: historyTypeToStatus[hist.eventType],
          });
        }
      });

      setHistory(newHist);
    }
  }, [leadHistory, user]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Box
      // gridColumn="span 2"
      // gridRow="span 4"
      flex="0 0 20%"
      display="flex"
      height="100%"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      mt="20px"
      backgroundColor={theme.palette.background.alt}
      borderRadius="0.55rem"
    >
      <Typography variant="h5" color={theme.palette.secondary[300]}>
        Lead History
      </Typography>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {history &&
          history.map((event, index) => {
            return (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot />
                  {index !== history.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    variant="h6"
                    component="span"
                    color={theme.palette.secondary[300]}
                  >
                    {event.eventType}
                  </Typography>
                  <Typography
                    style={{
                      fontStyle: "small",
                      color: theme.palette.grey[900],
                    }}
                  >
                    {event.info}
                  </Typography>
                  <Typography
                    style={{
                      fontStyle: "small",
                      color: theme.palette.grey[700],
                    }}
                  >
                    {formatDate(event.timestamp)}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
      <Box>
        <Button
          sx={{ display: "block", mt: 2 }}
          onClick={(e) => setOpen(true)}
          variant="contained"
        >
          Add New History Document
        </Button>
        <Dialog disableEscapeKeyDown open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Add a New History Document</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel id="new-hist-doc-label">Event Type</InputLabel>
              <Select
                labelId="new-hist-doc-label"
                id="new-hist-doc"
                label="Event Type"
                onChange={(e) => setNewHist(e.target.value)}
              >
                {newHistOptions.map((opt, indx) => {
                  return (
                    <MenuItem key={indx} value={opt}>
                      {opt}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleNewHistDoc()} variant="contained">
              Submit Event
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default LeadHistory;
