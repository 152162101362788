import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import Header from "components/Header";
import SectionHeader from "components/SectionHeader";
import AddTeamMember from "./AddTeamMember";
import TeamMembersTable from "./TeamMembersTable";
import { useSelector } from "react-redux";
import { useGetAllClientsQuery } from "state/api";
// import UserTable from "./UserTable";

function ClientSettings() {
  const user = useSelector((state) => state.auth.user);
  const { data: allClients } = useGetAllClientsQuery();
  const [client, setClient] = useState();

  useEffect(() => {
    const client = allClients?.filter((c) => c.email == user.email);
    client ? setClient(client[0]) : setClient();
  }, [user, allClients]);

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="SETTINGS" subtitle="Manage Your Team Members" />
      {client ? (
        <Box
          sx={{ display: "flex", justifyContent: "space-between", gap: "20px" }}
        >
          <Box sx={{ mt: "20px", width: "40%" }}>
            <SectionHeader title="ADD NEW TEAM MEMBER" />
            <AddTeamMember clientId={client._id} />
          </Box>
          <Box sx={{ mt: "20px", width: "60%" }}>
            <SectionHeader title="LIST OF ALL TEAM MEMBERS" />
            <TeamMembersTable client={client} />
          </Box>
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
}

export default ClientSettings;
