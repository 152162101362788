import { Box } from "@mui/material";
import Header from "components/Header";
import AddTeamMember from "../settings/AddTeamMember";
import { useSelector } from "react-redux";
import { useGetAllClientsQuery } from "state/api";
import { useEffect, useState } from "react";

const CreateTeamMember = () => {
  const user = useSelector((state) => state.auth.user);
  const { data: allClients } = useGetAllClientsQuery();
  const [client, setClient] = useState();

  useEffect(() => {
    const client = allClients?.filter((c) => c.email == user.email);
    client ? setClient(client[0]) : setClient();
  }, [user, allClients]);

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="ADD NEW TEAM MEMBER"
        subtitle="Input the following details to add a new team member."
      />
      {client ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
          <Box sx={{ width: "600px" }}>
            <AddTeamMember clientId={client?._id} navigateTo="/teammembers" />
          </Box>
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};
export default CreateTeamMember;
