import React, { useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { Outlet } from 'react-router-dom' // renders child components wherever this element exists, allows Navbar/Sidebar to be on every page
// import { useSelector } from 'react-redux'

// import components
import Navbar from "components/Navbar"
import Sidebar from "components/Sidebar"

function Layout() {
  const isNonMobile = useMediaQuery("(min-width: 600px)") // returns boolean to determine if app is mobile
  const [isSidebarOpen, setIsSidebarOpen] = useState(isNonMobile)
  // const userId = useSelector((state) => state.global.userId)
  // const { data } = useGetUserQuery(userId)

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <Sidebar
        // user={data || {}} 
        isNonMobile={isNonMobile}
        drawerWidth="180px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box flexGrow={1}>
        <Navbar 
          // user={data || {}} 
          isNonMobile={isNonMobile}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Outlet context={isSidebarOpen}/>
      </Box>
    </Box>
  )
}

export default Layout