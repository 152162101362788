import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  useTheme,
  Box,
  useMediaQuery,
} from "@mui/material";
import {
  useGetAllClientsQuery,
  useGetAllProjectsQuery,
  useGetClientLeadsQuery,
  useGetClientWeeklySummaryQuery,
} from "state/api";
import { useSelector } from "react-redux";
import { formatISO, addDays } from "date-fns";
import ClientLeadCompletion from "./ClientLeadCompletion";
import FeedbackBox from "./FeedbackBox";

function ClientDashboard() {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const user = useSelector((state) => state.auth.user);
  const { data: allClients } = useGetAllClientsQuery();
  const [client, setClient] = useState();

  useEffect(() => {
    const client = allClients?.filter((c) => c.email == user.email);
    client ? setClient(client[0]) : setClient();
  }, [user, allClients]);

  // To get information on the leads
  // Get today's date
  const today = new Date();

  // Set start date to today and end date to tomorrow
  const startDate = formatISO(today, { representation: "date" });
  const tomorrow = addDays(today, 1);
  const endDate = formatISO(tomorrow, { representation: "date" });

  const { data: leadData } = useGetClientLeadsQuery({
    id: client?._id,
    page: 0,
    pageSize: 10000,
    sort: JSON.stringify({ field: "createdAt", sort: "desc" }),
    startDate,
    endDate,
  });

  const { data: weeklySummary } = useGetClientWeeklySummaryQuery(client?._id);
  console.log("🚀 ~ ClientDashboard ~ weeklySummary:", weeklySummary);
  const feedbackTypes = [
    "No Answer",
    "Interested",
    "Not Interested",
    "Switched Off",
    "Call Back",
    "Duplicate",
    "Follow Up",
    "Wrong Number",
    "Low Budget",
  ];
  const noF = weeklySummary?.noFeedback;

  return (
    <Box m="1.5rem 2.5rem">
      {client ? (
        <Box>
          <Container
            maxWidth="md"
            style={{ textAlign: "center", marginTop: "50px" }}
          >
            <Typography
              variant="h2"
              color={theme.palette.primary[500]}
              fontWeight="bold"
              sx={{ mb: "5px" }}
            >
              Welcome {client.name}
            </Typography>
            <Typography variant="h5" color={theme.palette.primary[500]}>
              Below is a summary of your leads. You can search specific leads in
              the search bar above. You can view all leads using the "Leads" tab
              to the left.
            </Typography>
          </Container>

          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="40px"
            gap="20px"
            sx={{
              "& > div": {
                gridColumn: isNonMediumScreens ? undefined : "span 12",
              },
            }}
          >
            <Box
              display="flex"
              gridColumn="span 12"
              gridRow="span 1"
              alignItems="center"
            >
              <Typography
                variant="h4"
                color={theme.palette.primary[500]}
                fontWeight="bold"
                sx={{ mb: "5px" }}
              >
                Summary of Today and This Week's Leads
              </Typography>
            </Box>

            <ClientLeadCompletion
              client={client}
              leadData={leadData}
              weekly={"day"}
            />
            <ClientLeadCompletion
              client={client}
              leadData={weeklySummary}
              weekly={"week"}
            />

            {weeklySummary && (
              <Box
                display="flex"
                gridColumn="span 12"
                gridRow="span 1"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  color={theme.palette.primary[500]}
                  fontWeight="bold"
                  sx={{ mb: "5px" }}
                >
                  Summary of Feedback This Week |{" "}
                  {noF == 0
                    ? `You Are Caught Up On Feedback!`
                    : `You Have ${noF} ${noF == 1 ? "Lead" : "Leads"} That ${
                        noF == 1 ? "Needs" : "Need"
                      } Your Feedback`}
                </Typography>
              </Box>
            )}

            {weeklySummary &&
              feedbackTypes.map((feedbackType) => (
                <FeedbackBox
                  key={feedbackType}
                  feedbackType={feedbackType}
                  count={weeklySummary.feedbackCount[feedbackType]}
                  totalLeads={weeklySummary.total}
                />
              ))}
          </Box>
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
}

export default ClientDashboard;
