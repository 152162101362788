import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { useGetAllClientsQuery, useGetAllProjectsQuery } from "state/api";

function ClientProjectInformationCard({ lead }) {
  const theme = useTheme();
  const { data: clients } = useGetAllClientsQuery();
  const { data: projects } = useGetAllProjectsQuery();
  const [clientProject, setClientProject] = useState();

  useEffect(() => {
    const client =
      clients?.find((c) => c._id === lead.clientId)?.name || "Unassigned";
    const project =
      projects?.find((p) => p._id === lead.projectId)?.name || "Unassigned";

    setClientProject({ client, project });
  }, [clients, projects, lead]);

  return (
    <Box
      gridColumn="span 6"
      gridRow="span 4"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      color={theme.palette.secondary[300]}
      borderRadius="0.55rem"
    >
      <Typography variant="h5" color={theme.palette.secondary[300]}>
        Client and Project Information
      </Typography>
      <FlexBetween>
        <Typography sx={{ mr: "5px" }}>Project:</Typography>
        <Typography fontWeight="bold">{clientProject?.project}</Typography>
      </FlexBetween>
      <FlexBetween>
        <Typography sx={{ mr: "5px" }}>Client: </Typography>
        <Typography fontWeight="bold">{clientProject?.client}</Typography>
      </FlexBetween>
    </Box>
  );
}

export default ClientProjectInformationCard;
