import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  mode: "dark",
}

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state, action) => {
      if (action.payload) {
        state.mode = action.payload;
      } else {
        state.mode = state.mode === 'light' ? 'dark' : 'light';
      }
    }
  }
})

export const { setMode } = globalSlice.actions

export default globalSlice.reducer