import { createSlice } from "@reduxjs/toolkit";

const createConfigToken = (token) => {
  // generate configToken
  if (token) {
    const configToken = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return configToken;
  } else {
    return;
  }
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(localStorage.getItem("user"))?.user || null,
    token: JSON.parse(localStorage.getItem("user"))?.token || null,
    configToken:
      createConfigToken(JSON.parse(localStorage.getItem("user"))?.token) ||
      null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;
      state.configToken = createConfigToken(token);
    },
    logOut: (state, action) => {
      state.user = null;
      state.token = null;
      state.configToken = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;
