import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "./FlexBetween";
import { useGetSingleProjectQuery } from "state/api";

function ClientStatBox({ _id, numberLeadsRequested, numberLeadsReceived }) {
  const theme = useTheme();
  const { data } = useGetSingleProjectQuery(_id);

  return (
    <Box
      gridColumn="span 2"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      color={theme.palette.secondary[200]}
      borderRadius="0.55rem"
    >
      <Typography
        variant="h4"
        fontWeight="600"
        sx={{ color: theme.palette.secondary[200] }}
      >
        {data?.name}
      </Typography>
      <FlexBetween>
        <Typography>Leads Requested:</Typography>
        <Typography fontWeight="bold">{numberLeadsRequested}</Typography>
      </FlexBetween>
      <FlexBetween>
        <Typography>Leads Recieved:</Typography>
        <Typography fontWeight="bold">{numberLeadsReceived || 0}</Typography>
      </FlexBetween>
    </Box>
  );
}

export default ClientStatBox;
