import { useParams, useNavigate } from "react-router-dom";
import { useGetSingleClientQuery } from "state/api";
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Box,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiPhoneNumber from "material-ui-phone-number-2";
import Header from "components/Header";
import { useGetAllProjectsQuery } from "state/api";
import axios from "axios";
import { useSelector } from "react-redux";

const initialProject = { project: "", numberOfLeads: "", _id: "" };

function UpdateClient() {
  const { id } = useParams();
  const { data } = useGetSingleClientQuery(id);
  const { data: projectsData, isLoading: projectsLoading } =
    useGetAllProjectsQuery();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    country: "",
    email: "",
    password: "",
    phoneNumber: "",
    projects: [],
  });

  const [showFields, setShowFields] = useState(true);

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (data && projectsData) {
      const formattedProjects = data.projects.map((proj) => {
        const currentProj = projectsData.find(
          (projData) => projData._id === proj.project
        );
        return {
          project: currentProj?.name,
          numberOfLeads: proj.numberLeads,
          _id: proj.project,
        };
      });
      setFormData({ ...data, projects: formattedProjects, password: "" });
    }
  }, [data, projectsData]);

  const handleProjectChange = (index, type, value) => {
    const updatedProjects = formData.projects.map((project, i) => {
      if (i === index) {
        if (type === "project") {
          return { ...project, [type]: value[0], _id: value[1] };
        } else {
          return { ...project, [type]: value };
        }
      }
      return project;
    });
    setFormData((prevData) => ({
      ...prevData,
      projects: updatedProjects,
    }));
  };

  const removeProject = (index) => {
    const updatedProjects = formData.projects.filter((_, i) => index !== i);
    setFormData((prevData) => ({
      ...prevData,
      projects: updatedProjects,
    }));
  };

  const addProject = () => {
    setFormData((prevData) => ({
      ...prevData,
      projects: [...prevData.projects, initialProject],
    }));
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}clientsServer/clients/update/${id}`,
        formData,
        configToken
      );
      console.log(response);
      navigate(`/clients/${id}`);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={`EDIT ${data?.name}`}
        subtitle={`Edit the following information for ${data?.name}`}
      />
      <Box display="flex" justifyContent="center" sx={{ mt: "20px" }}>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "600px",
          }}
        >
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            required
          />
          <TextField
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
            required
          />
          <FormControl fullWidth required>
            <InputLabel>Country</InputLabel>
            <Select
              name="country"
              value={formData.country}
              onChange={handleInputChange}
            >
              <MenuItem value="Egypt">Egypt</MenuItem>
              <MenuItem value="UAE">UAE</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            fullWidth
            required
          />
          <TextField
            label="New Password (leave blank to not change password)"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            fullWidth
            required
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={showFields}
                  onChange={() => setShowFields((prev) => !prev)}
                  color="primary"
                />
              }
              label="Collect Phone Number and Project Information"
            />
          </FormGroup>
          {showFields && (
            <Box>
              <MuiPhoneNumber
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={(value) =>
                  handleInputChange({ target: { name: "phoneNumber", value } })
                }
                fullWidth
                disableAreaCodes
                defaultCountry="eg"
                preferredCountries={["eg", "ae"]}
                required
                overflow="visible"
                sx={{ "& svg": { height: "1em" } }}
              />
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
              >
                <Typography>Projects</Typography>
                {formData.projects.map((project, index) => (
                  <Box key={index} sx={{ display: "flex", gap: "4px" }}>
                    <FormControl fullWidth required>
                      <InputLabel>Project {index + 1}</InputLabel>
                      <Select
                        value={project.project}
                        // onChange={(e) => {
                        //   handleProjectChange(index, "project", e.target.value)
                        // }}
                      >
                        {projectsData ? (
                          projectsData
                            .slice()
                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
                            .map((option) => (
                              <MenuItem
                                key={option._id}
                                onClick={() =>
                                  handleProjectChange(index, "project", [
                                    option.name,
                                    option._id,
                                  ])
                                }
                                value={option.name}
                              >
                                {option.name}
                              </MenuItem>
                            ))
                        ) : (
                          <MenuItem value="" disabled>
                            Loading...
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <TextField
                      label="Number of Leads"
                      value={project.numberOfLeads}
                      onChange={(e) =>
                        handleProjectChange(
                          index,
                          "numberOfLeads",
                          e.target.value
                        )
                      }
                      required
                      fullWidth
                    />
                    <IconButton onClick={() => removeProject(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  onClick={addProject}
                  variant="outlined"
                  sx={{ mt: "10px" }}
                >
                  + Add Project
                </Button>
              </Box>
            </Box>
          )}
          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: "10px" }}
          >
            Submit
          </Button>
        </form>
      </Box>
    </Box>
  );
}

export default UpdateClient;
