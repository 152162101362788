import React, { useState, useEffect } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Header from "components/Header";
import TotalLeadsProgress from "./TotalLeadsProgress";
import StatBox from "./StatBox";
import {
  useGetAllProjectsQuery,
  useGetTodayLeadsQuery,
  useGetTotalUnassignedLeadsQuery,
} from "state/api";
import { useGetAllClientsQuery } from "state/api";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import PeopleIcon from "@mui/icons-material/People";

function Dashboard() {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const { data: clients } = useGetAllClientsQuery();
  const { data: projects } = useGetAllProjectsQuery();
  const { data: numUnassignedLeads } = useGetTotalUnassignedLeadsQuery();
  const { data: todayLeads } = useGetTodayLeadsQuery();

  const [egyptClients, setEgyptClients] = useState();
  const [uaeClients, setUaeClients] = useState();
  const [egyptProjects, setEgyptProjects] = useState();
  const [uaeProjects, setUaeProjects] = useState();
  const [unassignedProjects, setUnassignedProjects] = useState();

  useEffect(() => {
    let egyptCl = 0;
    let uaeCl = 0;
    let egyptProj = 0;
    let uaeProj = 0;
    let unassignedProj = 0;

    clients?.forEach((client) => {
      if (client.country === "Egypt") {
        egyptCl += 1;
      } else if (client.country === "UAE") {
        uaeCl += 1;
      }
    });

    setEgyptClients(egyptCl);
    setUaeClients(uaeCl);

    projects?.forEach((project) => {
      if (project.country === "Egypt") {
        egyptProj += 1;
      } else if (project.country === "UAE") {
        uaeProj += 1;
      } else {
        unassignedProj += 1;
      }
    });

    setEgyptProjects(egyptProj);
    setUaeProjects(uaeProj);
    setUnassignedProjects(unassignedProj);
  }, [clients, projects]);

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {clients ? (
          <StatBox
            title="Total Clients"
            value={clients.length}
            icon={
              <PermIdentityIcon sx={{ color: theme.palette.secondary[200] }} />
            }
            egypt={egyptClients}
            uae={uaeClients}
          />
        ) : (
          <>Loading...</>
        )}
        {projects ? (
          <StatBox
            title="Total Projects"
            value={projects.length}
            icon={
              <LocationCityIcon sx={{ color: theme.palette.secondary[200] }} />
            }
            egypt={egyptProjects}
            uae={uaeProjects}
            unassigned={unassignedProjects}
          />
        ) : (
          <>Loading...</>
        )}
        {numUnassignedLeads ? (
          <StatBox
            title="Number of Unassigned Leads"
            value={numUnassignedLeads.total}
            icon={
              <PriorityHighIcon sx={{ color: theme.palette.secondary[200] }} />
            }
          />
        ) : (
          <>Loading...</>
        )}
        {todayLeads ? (
          <StatBox
            title="Number of Leads Collected Today"
            value={todayLeads.numLeads}
            icon={<PeopleIcon sx={{ color: theme.palette.secondary[200] }} />}
          />
        ) : (
          <>Loading...</>
        )}
        {clients ? <TotalLeadsProgress clients={clients} /> : <>Loading...</>}
      </Box>
    </Box>
  );
}

export default Dashboard;
