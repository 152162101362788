import React, { useState } from 'react'
import { Box, TextField, Button, Typography } from '@mui/material'
import MuiPhoneNumber from 'material-ui-phone-number-2'
import axios from 'axios'
import { useSelector } from 'react-redux'

function AddAdminUser() {
  const [name, setName] = useState("")
  const [occupation, setOccupation] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")

  // handle errors
  const [errorMessage, setErrorMessage] = useState("")
  const [errorFields, setErrorFields] = useState([])

  // generate configToken
  const token = useSelector((state) => state.auth.token)
  const configToken = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault();
      const signupData = { name, occupation, email, password, role: "Admin", phoneNumber }
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}usersServer/signup`, signupData, configToken)
      window.location.reload()  
    } catch (error) {
      console.log(error)
      if (error.response.data.message === "Email already exists.") {
        setErrorFields(["email"])
        setErrorMessage("Email already exists. Please choose a different email.")
      }
    }
  }

  const emailError = errorFields.includes('email') ? 'red' : 'transparent';

  return (
    <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <TextField
        label="Name"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        required
      />
      <TextField
        label="Occupation"
        value={occupation}
        type="text"
        onChange={(e) => setOccupation(e.target.value)}
        fullWidth
        required
      />
      <MuiPhoneNumber
        label="Phone Number"
        name="phoneNumber"
        value={phoneNumber}
        onChange={(value) => setPhoneNumber(value)}
        fullWidth
        disableAreaCodes
        defaultCountry="eg"
        preferredCountries={["eg", "ae"]}
        required
        overflow="visible"
        sx={{ '& svg': { height: '1em', }, }}
      />
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        required
        sx={{ border: `1px solid ${emailError}`}}
      />
      <TextField
        label="Password"
        value={password}
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        required
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: "10px" }}>Submit</Button>
      {errorMessage && <Typography sx={{mt: "30px", color: "red"}}>{errorMessage}</Typography>}
    </form>
  )
}

export default AddAdminUser