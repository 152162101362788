import React from "react";
import { Search } from "@mui/icons-material";
import {
  IconButton,
  TextField,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";
import { ExportExcelButton } from "./downloadExcel/ExportExcelButton";

function DataGridCustomToolbar({
  searchInput,
  setSearchInput,
  setSearch,
  columns,
  filename,
  selectedRows = [],
}) {
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const theme = useTheme();

  return (
    <GridToolbarContainer sx={{ color: theme.palette.primary[500] }}>
      <FlexBetween width="100%">
        <FlexBetween>
          <GridToolbarColumnsButton
            sx={{ color: theme.palette.primary[500] }}
          />
          <GridToolbarDensitySelector
            sx={{ color: theme.palette.primary[500] }}
          />
          {/* <GridToolbarExport /> */}
          <ExportExcelButton
            columns={columns}
            filename={filename}
            selectedRows={selectedRows}
            sx={{ color: theme.palette.secondary[500] }}
          />
        </FlexBetween>
        <FlexBetween>
          {isNonMediumScreens && (
            <TextField
              label="Search Campaign Name..."
              sx={{ mb: "0.5rem", width: "15rem" }}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearch(searchInput);
                }
              }}
              value={searchInput}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setSearch(searchInput);
                        // setSearchInput("");
                      }}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </FlexBetween>
      </FlexBetween>
    </GridToolbarContainer>
  );
}

export default DataGridCustomToolbar;
