import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useGetSingleProjectQuery } from 'state/api';
import { TextField, Box, Typography, Button, MenuItem, FormControl, InputLabel, Select } from '@mui/material'
import Header from 'components/Header'
import axios from 'axios';
import { useSelector } from 'react-redux';

function UpdateProject() {
  const { id } = useParams();
  const { data } = useGetSingleProjectQuery(id)

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [campaignPrefix, setCampaignPrefix] = useState("")
  const [country, setCountry] = useState("")

  // generate configToken
  const token = useSelector((state) => state.auth.token)
  const configToken = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
  }

  useEffect(() => {
    if (data) {
      setName(data.name);
      setDescription(data.description);
      setCampaignPrefix(data.campaignPrefix);
      setCountry(data.country);
    }
  }, [data]);

  const [emptyFields, setEmptyFields] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}projectsServer/projects/update/${id}`, {name, description, campaignPrefix : campaignPrefix.toLowerCase(), country}, configToken)
      navigate(`/projects/${id}`)
      window.location.reload()
    } catch (error) {
      console.log(error)
      if (error.response.data.message === "Campaign Prefix Already Exists") {
        setEmptyFields("campaignPrefix")
        setErrorMessage("Campaign Prefix already exists. Please choose a different one.")
      } else {
        setEmptyFields(error.response.data.emptyFields)
        setErrorMessage("Please fill in all fields.")
      }
    }
  }

  const nameError = emptyFields.includes('name') ? 'red' : 'transparent';
  const descriptionError = emptyFields.includes('description') ? 'red' : 'transparent';
  const campaignPrefixError = emptyFields.includes('campaignPrefix') ? 'red' : 'transparent';
  const countryError = emptyFields.includes('country') ? 'red' : 'transparent';

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={`EDIT ${data.name}`} subtitle="Update the following information and press submit to edit the project."/>
      <Box display="flex" justifyContent="center">
        <Box width="600px">
          <form>
            <Typography variant='h5' sx={{ mb: "5px" }}>Project Name</Typography>
            <TextField
              sx={{ mb: "5px", fontSize: "14px", border: `1px solid ${nameError}`, width: "100%" }}
              type='text'
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <Typography variant='h5' sx={{ mb: "5px" }}>Project Description</Typography>
            <TextField
              sx={{ mb: "5px", fontSize: "14px", border: `1px solid ${descriptionError}`, width: "100%" }} 
              type='text'
              multiline
              rows={4}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
            <Typography variant='h5' sx={{ mb: "5px" }}>Set Campaign Prefix for the Project</Typography>
            <TextField
              sx={{ mb: "5px", fontSize: "14px", border: `1px solid ${campaignPrefixError}`, width: "100%" }} 
              type='text'
              onChange={(e) => setCampaignPrefix(e.target.value)}
              value={campaignPrefix}
            />
            <Typography variant='h5' sx={{ mb: "5px" }}>Where is the Project Located</Typography>
            <FormControl fullWidth>
              <InputLabel>Select a country.</InputLabel>
              <Select value={country} onChange={(e) => setCountry(e.target.value)} sx={{ border: `1px solid ${countryError}`, width: "100%" }}>
                <MenuItem value="Egypt">Egypt</MenuItem>
                <MenuItem value="UAE">UAE</MenuItem>
              </Select>
            </FormControl>
            <br />
            <Button variant="contained" color="primary" sx={{ mt: "10px" }} onClick={handleSubmit}>Submit</Button>
          </form>
          {errorMessage && <Typography sx={{mt: "30px", color: "red"}}>{errorMessage}</Typography>}
        </Box>
      </Box>
    </Box>
  )
}

export default UpdateProject