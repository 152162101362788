import React from "react";
import {
  Typography,
  Container,
  useTheme,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  useGetTeamMemberByEmailQuery,
  useGetTeamMemberFeedbackSummaryQuery,
} from "state/api";
import FeedbackBox from "pages/clientPages/FeedbackBox";

function TeamMemberDashboard() {
  const user = useSelector((state) => state.auth.user);
  const { data } = useGetTeamMemberByEmailQuery(user?.email);
  const teamMember = data ? data.teamMember : undefined;

  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const { data: weeklySummary } = useGetTeamMemberFeedbackSummaryQuery(
    teamMember?._id
  );
  const feedbackTypes = [
    "No Answer",
    "Interested",
    "Not Interested",
    "Switched Off",
    "Call Back",
    "Duplicate",
    "Follow Up",
    "Wrong Number",
    "Low Budget",
  ];
  const noF = weeklySummary?.noFeedback;

  return (
    <Box m="1.5rem 2.5rem">
      {teamMember ? (
        <Box>
          <Container
            maxWidth="md"
            style={{ textAlign: "center", marginTop: "50px" }}
          >
            <Typography
              variant="h2"
              color={theme.palette.primary[500]}
              fontWeight="bold"
              sx={{ mb: "5px" }}
            >
              Welcome {teamMember.name}
            </Typography>
            <Typography variant="h5" color={theme.palette.primary[500]}>
              Below is a summary of your leads. You can search specific leads in
              the search bar above. You can view all leads using the "Leads" tab
              to the left.
            </Typography>
          </Container>

          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="40px"
            gap="20px"
            sx={{
              "& > div": {
                gridColumn: isNonMediumScreens ? undefined : "span 12",
              },
            }}
          >
            {weeklySummary && (
              <Box
                display="flex"
                gridColumn="span 12"
                gridRow="span 1"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  color={theme.palette.primary[500]}
                  fontWeight="bold"
                  sx={{ mb: "5px" }}
                >
                  Summary of Feedback |{" "}
                  {noF == 0
                    ? `You Are Caught Up On Feedback!`
                    : `You Have ${noF} ${noF == 1 ? "Lead" : "Leads"} That ${
                        noF == 1 ? "Needs" : "Need"
                      } Your Feedback`}
                </Typography>
              </Box>
            )}

            {weeklySummary &&
              feedbackTypes.map((feedbackType) => (
                <FeedbackBox
                  key={feedbackType}
                  feedbackType={feedbackType}
                  count={weeklySummary.feedbackCount[feedbackType]}
                  totalLeads={weeklySummary.total}
                />
              ))}
          </Box>
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
}

export default TeamMemberDashboard;
