import React, { useEffect, useState } from "react";
import { Box, useTheme, TextField, MenuItem, Button } from "@mui/material";
import {
  useGetAllClientsQuery,
  useGetAllLeadsQuery,
  useGetUniqueCampaignNamesQuery,
  useGetAllProjectsQuery,
} from "state/api";
import Header from "components/Header";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import FlexBetween from "components/FlexBetween";
import BulkAssign from "./BulkAssign";
import useUnassignLeads from "hooks/useUnassignLead";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setMode } from "state";
import { useNavigate, useOutletContext } from "react-router-dom";
import useStoreExistingFilters from "hooks/useStoreExistingFilters";
import CreateLead from "./CreateLead";
import { SourceMap } from "constants/ValueMaps";
import { feedbackTypes } from "constants/SelectOptions";

function Leads() {
  const isSidebarOpen = useOutletContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });
  const [search, setSearch] = useState("");

  const [campaignFilter, setCampaignFilter] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientFilter, setClientFilter] = useState("");
  const [feedbackFilter, setFeedbackFilter] = useState("");

  dayjs.extend(utc);
  const defaultStartDate = dayjs().subtract(2, "day").utc(true).toISOString();
  const [startDate, setStartDate] = useState(defaultStartDate);

  const defaultEndDate = dayjs().subtract(1, "day").utc(true).toISOString();
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [selectedProject, setSelectedProject] = useState({
    _id: "",
    name: "",
    campaignPrefix: "",
  });

  const [searchInput, setSearchInput] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCreateLeadOpen, setIsCreateLeadOpen] = useState(false);
  const [isPointer, setIsPointer] = useState(false);

  const mode = useSelector((state) => state.global.mode);
  console.log("🚀 ~ Leads ~ mode:", mode);

  const { unassignLeads, isLoading, error } = useUnassignLeads();
  console.log("🚀 ~ Leads ~ isLoading:", isLoading);
  const navigate = useNavigate();

  const { data } = useGetAllLeadsQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort ? sort : { field: "createdAt", sort: "desc" }),
    search,
    campaignFilter,
    startDate,
    endDate,
    clientFilter,
    feedbackFilter,
    campaignPrefix: selectedProject.campaignPrefix.toLowerCase(),
  });

  const { data: uniqueCampaignNames } = useGetUniqueCampaignNamesQuery();
  const { data: clients } = useGetAllClientsQuery();
  const { data: allProjects } = useGetAllProjectsQuery();

  // generate configToken
  const token = useSelector((state) => state.auth.token);
  const configToken = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [displayLeads, setDisplayLeads] = useState();
  console.log("🚀 ~ Leads ~ displayLeads:", displayLeads);
  useEffect(() => {
    if (data && clients) {
      let tempArray = [];
      for (const i in data.leads) {
        const singleLead = data.leads[i];
        const newDisplay = {
          ...singleLead,
          clientName:
            clients.find((c) => c._id === singleLead.clientId)?.name || "",
        };
        tempArray.push(newDisplay);
      }
      setDisplayLeads(tempArray);
    }
  }, [data, clients]);

  useEffect(() => {
    // Assuming unassignLeads sets isLoading to false when it's done
    if (!isLoading) {
      window.location.reload();
    }
  }, [isLoading]);

  const filters = {
    startDate,
    endDate,
    clientFilter,
    clientName,
    feedbackFilter,
    mode,
  };

  const { storeExistingFilters, getAndRemoveFilters } =
    useStoreExistingFilters();

  const handleUnassignLeads = async () => {
    storeExistingFilters(filters);

    if (selectedRows.length > 0) {
      try {
        await unassignLeads(selectedRows, "client");
      } catch (error) {
        console.error("Error while unassigning leads:", error);
        // Handle error if needed
      }
    }
  };

  const handleDeleteLeads = async () => {
    storeExistingFilters(filters);

    if (selectedRows.length > 0) {
      try {
        const deleteRequests = selectedRows.map((row) => {
          return axios.delete(
            `${process.env.REACT_APP_BASE_URL}leadsServer/leads/deleteLead/${row}`,
            configToken
          );
        });

        await Promise.all(deleteRequests);
        window.location.reload();
      } catch (error) {
        console.error("Error while deleting leads: ", error);
      }
    }
  };

  const handlePopupClose = () => {
    storeExistingFilters(filters);
    setIsPopupOpen(false);
  };

  const handlePopupOpen = () => {
    if (selectedRows.length > 0) {
      setIsPopupOpen(true);
    }
  };

  useEffect(() => {
    // Get and remove filters from localStorage
    const filters = getAndRemoveFilters();

    if (filters) {
      // Apply filters to state
      setStartDate(filters.startDate || "");
      setEndDate(filters.endDate || "");
      setClientFilter(filters.clientFilter || "");
      setClientName(filters.clientName || "");
      setFeedbackFilter(filters.feedbackFilter || "");

      // Apply mode filter from localStorage
      dispatch(setMode(filters.mode));
    }
  }, []);

  const handleFeedbackUpdate = async (updatedRow, originalRow) => {
    try {
      const diff = getDifferentValuesFromObject(updatedRow, originalRow);
      diff.forEach(async (key) => {
        if (key === "campaignName") {
          const response = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editCampaignName/${updatedRow._id}`,
            {
              campaignName: updatedRow.campaignName,
            },
            configToken
          );
          updateLocalLead(updatedRow);
        } else if (key == "feedback" || key == "writtenFeedback") {
          const response = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}leadsServer/leads/editFeedback/${updatedRow._id}`,
            {
              feedback: updatedRow.feedback,
              writtenFeedback: updatedRow.writtenFeedback,
            },
            configToken
          );
          updateLocalLead(updatedRow);
        }
      });
      return updatedRow;
    } catch (error) {
      console.log("🚀 ~ handleFeedbackUpdate ~ error:", error);
    }
  };

  const getDifferentValuesFromObject = (newRow, oldRow) => {
    const keys1 = Object.keys(newRow);
    const keys2 = Object.keys(oldRow);

    const differentKeys = [];

    // Iterate through keys of obj1
    keys1.forEach((key) => {
      // Check if the key exists in obj2 and the values are different
      if (keys2.includes(key)) {
        if (newRow[key] !== oldRow[key]) {
          differentKeys.push(key);
        }
      } else {
        differentKeys.push(key);
      }
    });

    return differentKeys;
  };

  const updateLocalLead = (updatedRow) => {
    // find the index of the updatedRow in the leads array
    const index = displayLeads.findIndex((obj) => obj._id === updatedRow._id);
    if (index !== -1) {
      // If an object with the same _id is found, replace it with updatedRow
      let oldLeads = [...displayLeads];
      oldLeads.splice(index, 1, updatedRow);
      setDisplayLeads(oldLeads);
    }
  };

  const handleRowDoubleClick = async (params) => {
    if (params.field === "name") {
      const rowId = params.row._id;
      navigate(`/leads/${rowId}`);
    }
  };

  const handleCellOver = (event) => {
    const field = event.currentTarget.dataset.field;
    if (field === "name") {
      setIsPointer(true);
    }
  };

  const handleCellOut = (event) => {
    const field = event.currentTarget.dataset.field;
    if (field === "name") {
      setIsPointer(false);
    }
  };

  const handleCreateLeadClose = () => {
    setIsCreateLeadOpen(false);
    storeExistingFilters(filters);
  };

  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 0.2,
    // },
    {
      field: "createdAt",
      headerName: "Date",
      // flex: 0.3,
      width: 90,
      valueFormatter: (params) => {
        const date = new Date(params.value);

        // Get the local time components
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are 0-based
        const year = date.getFullYear();

        // Create the formatted date string
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
      },
    },
    {
      field: "source",
      headerName: "Source",
      // flex: 0.25,
      width: 30,
      valueFormatter: (params) => {
        return SourceMap[params.value];
      },
    },
    {
      field: "campaignName",
      headerName: "Campaign Name",
      // flex: 0.4,
      width: 150,
      editable: true,
    },
    {
      field: "name",
      headerName: "Full Name",
      // flex: 0.5,
      width: 150,
      renderCell: (props) => (
        <div
          style={{
            cursor: isPointer ? "pointer" : "default",
          }}
        >
          {props.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      // flex: 0.4,
      width: 150,
    },
    {
      field: "phoneNumberOne",
      headerName: "Phone Number 1",
      // flex: 0.5,
      width: 150,
    },
    {
      field: "phoneNumberTwo",
      headerName: "Phone Number 2",
      // flex: 0.5,
      width: 150,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      // flex: 0.3,
      width: 150,
    },
    {
      field: "leadCreationComments",
      headerName: "Automatically Assigned?",
      width: 200,
    },
    {
      field: "otherQuestions",
      headerName: "Other Questions",
      width: 200,
      renderCell: (params) => (
        <Box>
          {params.value.map((questionObj, index) => (
            <div key={index}>
              {`${questionObj.question} : ${questionObj.answer}`}
            </div>
          ))}
        </Box>
      ),
    },
    {
      field: "clientName",
      headerName: "Client",
      // flex: 0.4,
      width: 150,
    },
    {
      field: "feedback",
      headerName: "Feedback",
      // flex: 0.4,
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "No Answer",
        "Interested",
        "Not Interested",
        "Switched Off",
        "Call Back",
        "Duplicate",
        "Follow Up",
        "Wrong Number",
        "Low Budget",
        "",
      ],
    },
    {
      field: "writtenFeedback",
      headerName: "Notes",
      // flex: 0.4,
      width: 150,
      editable: true,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="LEADS" subtitle="List of All Leads" />
      <FlexBetween>
        <Box>
          {allProjects ? (
            <TextField
              select
              label="Select Project"
              sx={{ mb: "0.5rem", mr: "0.5rem", width: "15rem" }}
              value={selectedProject.name}
              variant="standard"
            >
              <MenuItem
                value="All"
                onClick={() =>
                  setSelectedProject({ _id: "", name: "", campaignPrefix: "" })
                }
              >
                All
              </MenuItem>
              {allProjects
                .slice() // Create a shallow copy of the array to avoid modifying the original array
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
                .map(({ _id, name, campaignPrefix }) => (
                  <MenuItem
                    key={_id}
                    value={name}
                    onClick={() =>
                      setSelectedProject({ _id, name, campaignPrefix })
                    }
                  >
                    {name}
                  </MenuItem>
                ))}
            </TextField>
          ) : (
            <>Loading...</>
          )}
          {clients ? (
            <TextField
              select
              label="Select Client"
              sx={{ mb: "0.5rem", mr: "0.5rem", width: "15rem" }}
              onChange={(e) => setClientName(e.target.value)}
              value={clientName}
              variant="standard"
            >
              <MenuItem value="All" onClick={() => setClientFilter("")}>
                All
              </MenuItem>
              <MenuItem
                value="Unassigned Leads"
                onClick={() => setClientFilter("Unassigned")}
              >
                Unassigned Leads
              </MenuItem>
              {clients
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
                .map((client) => (
                  <MenuItem
                    key={client.name}
                    value={client.name}
                    onClick={() => setClientFilter(client._id)}
                  >
                    {client.name}
                  </MenuItem>
                ))}
            </TextField>
          ) : (
            <>Loading...</>
          )}
          <TextField
            select
            label="Select Feedback"
            sx={{ mb: "0.5rem", mr: "0.5rem", width: "15rem" }}
            value={feedbackFilter}
            variant="standard"
          >
            <MenuItem value="All" onClick={() => setFeedbackFilter("")}>
              All
            </MenuItem>
            {feedbackTypes.map((f) => (
              <MenuItem key={f} value={f} onClick={() => setFeedbackFilter(f)}>
                {f}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FlexBetween>
              <Box sx={{ mr: "10px", ml: "10px" }}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: theme.palette.primary[500] }}
                  onClick={() => setIsCreateLeadOpen(true)}
                >
                  Create Lead
                </Button>
                {clients && allProjects && (
                  <CreateLead
                    open={isCreateLeadOpen}
                    onClose={() => handleCreateLeadClose()}
                    allClients={clients}
                    allProjects={allProjects}
                  />
                )}
              </Box>
              <Button
                variant="contained"
                onClick={handleDeleteLeads}
                sx={{ mr: "10px", backgroundColor: theme.palette.primary[500] }}
              >
                Delete Leads
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: theme.palette.primary[500] }}
                onClick={handleUnassignLeads}
              >
                Unassign Leads
              </Button>
              <Box sx={{ mr: "10px", ml: "10px" }}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: theme.palette.primary[500] }}
                  onClick={handlePopupOpen}
                >
                  Bulk Assign
                </Button>
                {clients && (
                  <BulkAssign
                    open={isPopupOpen}
                    onClose={handlePopupClose}
                    clients={clients}
                    selectedRows={selectedRows}
                  />
                )}
              </Box>
              <DatePicker
                label="Start Date"
                value={dayjs.utc(dayjs(startDate))}
                onChange={(newValue) => {
                  const utcDate = dayjs(newValue).utc(true);
                  setStartDate(utcDate?.toISOString() || null);
                }}
                textField={(params) => (
                  <TextField
                    {...params}
                    sx={{ mb: "0.5rem", width: "15rem" }}
                    variant="standard"
                  />
                )}
              />
              <DatePicker
                label="End Date"
                value={dayjs.utc(dayjs(endDate))}
                sx={{ ml: "10px" }}
                onChange={(newValue) => {
                  const utcDate = dayjs(newValue).utc(true);
                  setEndDate(utcDate?.toISOString() || null);
                }}
                textField={(params) => (
                  <TextField
                    {...params}
                    sx={{ mb: "0.5rem", width: "15rem" }}
                    variant="standard"
                  />
                )}
              />
            </FlexBetween>
          </LocalizationProvider>
        </Box>
      </FlexBetween>
      <Box
        height="80vh"
        maxWidth={isSidebarOpen ? "80vw" : "93vw"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders .MuiSvgIcon-root": {
            color: theme.palette.secondary[100],
          },
          // "& .MuiDataGrid-virtualScroller": {
          //   backgroundColor: theme.palette.primary.light
          // },
          "& .MuiDataGrid-footerContainer": {
            // backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.primary[500]} !important`,
          },
        }}
      >
        <DataGrid
          apiRef={apiRef}
          loading={!displayLeads || !data}
          autoWidth
          getRowId={(row) => row._id}
          rows={displayLeads || []}
          columns={columns}
          rowCount={(data && data.total) || 0}
          pageSizeOptions={[20, 50, 100]}
          pagination
          paginationModel={{ page: page, pageSize: pageSize }}
          paginationMode="server"
          sortingMode="server"
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          disableRowSelectionOnClick
          checkboxSelection
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={(newSelection) =>
            setSelectedRows(newSelection)
          }
          processRowUpdate={(updatedRow, originalRow) =>
            handleFeedbackUpdate(updatedRow, originalRow)
          }
          onProcessRowUpdateError={(error) => console.log(error)}
          onCellDoubleClick={handleRowDoubleClick}
          onCellEditStop={(params) => {
            console.log(`params: ${params}`);
          }}
          onPaginationModelChange={(newModel) => {
            setPage(newModel.page);
            setPageSize(newModel.pageSize);
          }}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          slots={{ toolbar: DataGridCustomToolbar }}
          slotProps={{
            toolbar: {
              searchInput,
              setSearchInput,
              setSearch,
              columns,
              filename: "All",
              selectedRows,
            },
            cell: {
              onMouseEnter: handleCellOver,
              onMouseLeave: handleCellOut,
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default Leads;
