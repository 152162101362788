import { MenuItem } from "@mui/material";
import React from "react";
import {
  gridFilteredSortedRowIdsSelector,
  gridRowSelectionStateSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";

import ExcelJS from "exceljs";
import FileSaver from "file-saver";

function handleExport(apiRef, config, selectedRows) {
  const data = getExcelData(apiRef, selectedRows);

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(config.sheetName);

  // Add a row above the header row
  const aboveHeaderRow = worksheet.addRow([]);
  const aboveHeaderCell = aboveHeaderRow.getCell(1);
  aboveHeaderRow.height = 25;
  aboveHeaderCell.font = { size: 16, bold: true, color: { argb: "000000" } };
  aboveHeaderCell.value = "Phieg Marketing Agency";
  aboveHeaderCell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "00FF80" },
  };
  aboveHeaderCell.alignment = { horizontal: "center", vertical: "middle" };
  worksheet.mergeCells(1, 1, 1, config.keys.length); // Merge cells to span the entire width

  // Add column headers
  worksheet.addRow(config.columnNames);

  // Add data rows
  data.forEach((row) => {
    const newRow = [];
    config.keys.forEach((key) => {
      newRow.push(row[key]);
    });
    worksheet.addRow(newRow);
  });

  // Format the header row
  const headerRow = worksheet.getRow(2);
  headerRow.eachCell({ includeEmpty: true }, (cell) => {
    cell.font = { bold: true, color: { argb: "000000" } };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "99FF99" },
    };
    cell.alignment = { horizontal: "center" };
  });

  // Format the date column
  const dateColumnIndex = config.keys.indexOf("updatedAt");
  if (dateColumnIndex !== -1) {
    for (let rowIdx = 3; rowIdx <= data.length + 2; rowIdx++) {
      const cell = worksheet.getCell(rowIdx, dateColumnIndex + 1);
      const dateValue = data[rowIdx - 3]["updatedAt"];

      if (dateValue) {
        const formattedDate = new Date(dateValue).toLocaleDateString("en-GB");
        cell.value = formattedDate;
      }
    }
  }

  // Auto-fit all columns excluding the first row
  worksheet.columns.forEach((column, colNumber) => {
    let maxLength = 0;

    // Start from the second row
    for (let rowIdx = 2; rowIdx <= data.length + 2; rowIdx++) {
      const cellValue = worksheet.getCell(rowIdx, colNumber + 1).value
        ? worksheet.getCell(rowIdx, colNumber + 1).value.toString()
        : "";
      maxLength = Math.max(maxLength, cellValue.length);
    }

    column.width = Math.min(30, maxLength + 2); // Set a maximum width of 30
  });

  // Write the workbook to a buffer
  workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      // Convert the buffer to a Blob
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Trigger a download
      FileSaver.saveAs(blob, config.filename);
      console.log("Excel file generated and downloaded successfully.");
    })
    .catch((error) => {
      console.error("Error generating Excel file:", error);
    });
}

function getExcelData(apiRef, selectedRows) {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
  let filteredToRowSelection = [];

  // If rows are selected, filter the data to only include selected rows
  if (selectedRows.length > 0) {
    filteredToRowSelection = selectedRows;
  } else {
    filteredToRowSelection = filteredSortedRowIds;
  }

  // Format the data. Here we only keep the value
  const data = filteredToRowSelection.map((id) => {
    const row = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });

    return row;
  });

  return data;
}

export function ExportMenuItem(props) {
  const apiRef = useGridApiContext();
  const { hideMenu } = props;

  const config = {
    columnNames: props.columns.map((column) => {
      return column.headerName;
    }),
    keys: props.columns.map((column) => {
      return column.field;
    }),
    filename: `${props.filename} Leads.xlsx`,
    sheetName: props.filename,
  };

  return (
    <MenuItem
      onClick={() => {
        handleExport(apiRef, config, props.selectedRows);
        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Download Excel
    </MenuItem>
  );
}
